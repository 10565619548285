import CheckTwo from '~icons/hawk/check-two?raw';
import CrossIcon from '~icons/hawk/cross?raw';
import dayjs from 'dayjs';

import DOMPurify from 'dompurify';
import { groupBy, isEqual } from 'lodash-es';
import { storeToRefs } from 'pinia';
import tippy from 'tippy.js';

import { defineAsyncComponent, h } from 'vue';
import { useTippy } from 'vue-tippy';
import { useCommonImports } from '~/common/composables/common-imports.composable';
import { changeIconDimensions, waitForElement } from '~/common/utils/common.utils';

import { useHelpers } from '~/project-management/composables/pm-helpers.composable';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const VueDatePicker = defineAsyncComponent(() => import('@vuepic/vue-datepicker'));

const IconCross = changeIconDimensions(CrossIcon, 8, 8);
const IconCheckTwo = changeIconDimensions(CheckTwo, 10, 10);

const state = reactive({
  editor_state: null,
});

export function useInlineEditors() {
  const project_management_store = useProjectManagementStore();
  const { openResourcesEditor } = useHelpers();
  const { $t, $toast } = useCommonImports();

  const { update_activity, add_or_update_custom_field_value, clear_custom_field_value, set_schedule_dirtiness, change_activity_id } = project_management_store;
  const { $g, active_task_uid, active_schedule, datepicker_tippy, is_schedule_editable, is_mini_loading, tippy_target } = storeToRefs(project_management_store);

  let inline_editor_events = [];

  function onConstraintSelectChange(event) {
    const constraint_type = $g.value.getTask(state.editor_state.id).constraint_type;
    if (constraint_type !== event.target.value)
      $g.value.ext.inlineEditors.save();
  }

  function onDatePickerShown(instance) {
    setTimeout(() => {
      const el = instance?.popper?.lastChild?.querySelector?.('[aria-selected="true"]');
      el?.focus?.();
    }, 100);
  }

  function setupInlineEditors() {
    function getInput(node) {
      return node.querySelector('input');
    };

    gantt.$onNamePaste = function (event, id) {
      // Get pasted data via clipboard API
      const clipboard_data = event.clipboardData || window.clipboardData;
      const pasted_data = clipboard_data.getData('Text');
      const lines = pasted_data.match(/[^\r\n]+/g).reverse();
      const line_0 = lines[0];
      const other_lines = lines.slice(1);

      const current_task = $g.value.getTask(id);
      if (line_0.includes('\t')) {
        current_task.text = line_0.split('\t')[0];
        const integer = line_0.split('\t')[1];
        current_task.weight = Number.parseFloat(integer) || 0;
      }
      else {
        current_task.text = line_0;
        current_task.weight = 0;
      }
      $g.value.updateTask(id);

      $g.value.ext.inlineEditors.hide();

      if (!other_lines.length)
        return;

      const parent = $g.value.getParent(id);
      const index = $g.value.getTaskIndex(id);

      $g.value.batchUpdate(() => {
        for (const str of other_lines) {
          const uid = crypto.randomUUID().substring(0, 8);
          let text = str;
          let weight = 0;

          if (str.includes('\t')) {
            text = str.split('\t')[0];
            const integer = str.split('\t')[1];
            weight = Number.parseFloat(integer) || 0;
          }

          $g.value.addTask({
            id: uid,
            uid,
            text: DOMPurify.sanitize(text, { ALLOWED_TAGS: [] }),
            duration: current_task.duration,
            start_date: current_task.start_date, // NOTE: for some reason, using parent.start_date does not work here
            end_date: current_task.end_date,
            type: $g.value.config.types.task,
            not_finalized: false,
            unscheduled: false,
            readonly: false,
            status: 'Not started',
            is_backend_save_pending: true,
            weight,
          }, parent, index);
          const task = $g.value.getTask(uid);
          // This is needed to update the store's copy after adding an activity to the gantt using addTask.
          active_schedule.value.activities[task.uid] = task;
        }
      });
      $g.value.showTask(id);
    };

    gantt.$onTextInput = function () {
      const textInput = document.getElementById('textInput');
      const btnSave = document.getElementById('btnSave');
      const text_value = textInput.value.trim();

      if (text_value.length > 0)
        btnSave.classList.remove('hidden');
      else
        btnSave.classList.add('hidden');
    };

    gantt.$onKeyDown = function (event, id) {
      if (event.key === 'Enter') {
        gantt.$saveEdit(id, true);
      }

      else if (event.key === 'Escape') {
        gantt.$cancelEdit(id);
      }

      else if (event.shiftKey) {
        if (
          event.code === 'ArrowRight'
          && $g.value.getTask(id).text.length !== 0
          && $g.value.getTask(id).text.length === document.getElementById('textInput').selectionStart
        ) {
          window.gantt.$indentOrOutdentTask('indent', event);
        }
        else if (
          event.code === 'ArrowLeft'
          && $g.value.getTask(id).text.length !== 0
          && document.getElementById('textInput').selectionStart === 0
        ) {
          window.gantt.$outdentByMove(id);
        }
      }
    };

    gantt.$saveEdit = function (id, add_another = true) {
      const textInput = document.getElementById('textInput');
      const text_value = textInput.value.trim();

      const t = $g.value.getTask(id);
      if (text_value)
        $g.value.ext.inlineEditors.save();
      else
        gantt.$cancelEdit(id);

      if (t?.not_finalized) {
        delete t.not_finalized;
        if (add_another) {
          const next_sibling = $g.value.getNextSibling(id);
          const surrogate_task = $g.value.getTask(next_sibling);
          if (surrogate_task)
            gantt.$addTaskOrMilestone(t.type, null, surrogate_task.id, surrogate_task.$wbs);
        }
      }
    };

    gantt.$cancelEdit = function (id) {
      const t = $g.value.getTask(id);
      $g.value.ext.inlineEditors.hide();
      if (t?.not_finalized && !t?.text)
        $g.value.isTaskExists(id) && $g.value.deleteTask(id);
    };

    gantt.$onTextInputBlur = function (that) {
      setTimeout(() => {
        that.focus();
      }, 10);
    };

    function prepareInputForEdit(el_id) {
      const el = document.getElementById(el_id);

      // hi future me, the autofocus attr creates new issues so this workaround is better
      el.focus();
      el.scrollLeft = el.scrollWidth;

      if (typeof el.selectionStart == 'number') {
        el.selectionStart = el.selectionEnd = el.value.length;
      }
      else if (typeof el.createTextRange != 'undefined') {
        const range = el.createTextRange();
        range.collapse(false);
        range.select();
      }
    }

    $g.value.config.editor_types.pm_id_editor = {
      show(id, column, _config, placeholder) {
        // called when input is displayed, put html markup of the editor into placeholder
        // and initialize your editor if needed:
        const task = $g.value.getTask(id);
        const is_parent = [$g.value.config.types.project].includes(task.type);
        placeholder.innerHTML = `<input task-id="${id}" id="idInput" type="text" name="${column.name}" class="${is_parent ? 'font-semibold' : ''}" value="${task[column.name]}">`;

        prepareInputForEdit('idInput');

        const the_input = document.querySelector('#idInput');
        the_input.addEventListener('blur', function () {
          gantt.$onTextInputBlur(this);
        });
        the_input.addEventListener('keydown', (event) => {
          if (event.key === 'Enter') {
            const idInput = document.getElementById('idInput');
            const text_value = idInput.value.trim();

            if (text_value && !$g.value.isTaskExists(text_value)) {
              change_activity_id(id, text_value);
              const activities_array = Object.values(active_schedule.value.activities);
              const current_task = groupBy(activities_array, 'id')[id][0];
              if (current_task?.uid)
                active_schedule.value.activities[current_task.uid].id = text_value;
              set_schedule_dirtiness();
            }
            else {
              if (task.id !== text_value && $g.value.isTaskExists(text_value)) {
                $toast({
                  title: $t('Failed to update the Activity ID'),
                  text: $t('The entered Activity ID already exists'),
                  type: 'error',
                });
              }
              gantt.$cancelEdit(id);
            }
          }
          else if (event.key === 'Escape') {
            gantt.$cancelEdit(id);
          }
        }, true);
      },
      set_value(value, _id, _column, node) {
        // set input value
        const sanitized_value = DOMPurify.sanitize(value, { ALLOWED_TAGS: [] });
        getInput(node).value = sanitized_value.trim();
      },
      get_value(_id, _column, node) {
        // return input value
        const sanitized_value = DOMPurify.sanitize(getInput(node).value || '', { ALLOWED_TAGS: [] });
        return sanitized_value.trim();
      },
      is_changed(value, _id, _column, node) {
        // called before save/close. Return true if new value differs from the original one
        // returning true will trigger saving changes, returning false will skip saving
        const sanitized_value = DOMPurify.sanitize(getInput(node).value || '', { ALLOWED_TAGS: [] });
        return sanitized_value !== value;
      },
      is_valid(_value, _id, _column, node) {
        // validate, changes will be discarded if the method returns false
        const sanitized_value = DOMPurify.sanitize(getInput(node).value || '', { ALLOWED_TAGS: [] });
        return !!sanitized_value?.trim?.()?.length;
      },
      hide() {
        const the_input = document.querySelector('#idInput');
        the_input?.blur?.();
      },
      save() {},
      focus() {},
    };

    $g.value.config.editor_types.pm_text = {
      show(id, column, _config, placeholder) {
        // called when input is displayed, put html markup of the editor into placeholder
        // and initialize your editor if needed:
        const task = $g.value.getTask(id);
        const task_level = $g.value.calculateTaskLevel(task) + 1;
        const is_parent = [$g.value.config.types.project].includes(task.type);
        const left_margin = (task_level * 15) + (is_parent ? 11 : -4) + 5;
        const html = `
        <div class="flex items-center bg-gray-100" style="padding-top: 2px; padding-bottom: 3px; padding-right: 6px;">
          <input task-id="${id}" id="textInput" type="text" name="${column.name}" class="!h-[22px] mt-[3px] mb-[3px] px-2 border border-gray-300 rounded ${is_parent ? 'font-semibold' : ''}" style="margin-left: ${left_margin}px;" value="${task[column.name]}">
          <button id="btnCancel" class="h-[22px] w-[24px] text-white-600 border border-gray-300 bg-white hover:bg-gray-50 ml-[3px] p-[6px] rounded">${IconCross}</button>
          <button id="btnSave" class="hidden h-[22px] w-[24px] text-white bg-primary-600 hover:bg-primary-700 ml-[3px] p-[6px] rounded">${IconCheckTwo}</button>
        </div>`;
        placeholder.innerHTML = html;

        prepareInputForEdit('textInput');

        const the_input = document.querySelector('#textInput');
        the_input.addEventListener('blur', function () {
          gantt.$onTextInputBlur(this);
        });
        the_input.addEventListener('input', () => {
          gantt.$onTextInput();
        });
        the_input.addEventListener('keydown', (event) => {
          gantt.$onKeyDown(event, id);
        }, true);
        the_input.addEventListener('paste', (event) => {
          gantt.$onNamePaste(event, id);
        });

        const btn_cancel = document.getElementById('btnCancel');
        btn_cancel.addEventListener('click', () => {
          gantt.$cancelEdit(id);
        });

        const btn_save = document.getElementById('btnSave');
        btn_save.addEventListener('click', () => {
          gantt.$saveEdit(id, false);
        });
      },
      set_value(value, _id, _column, node) {
        // set input value
        const sanitized_value = DOMPurify.sanitize(value, { ALLOWED_TAGS: [] });
        getInput(node).value = sanitized_value.trim();
      },
      get_value(_id, _column, node) {
        // return input value
        const sanitized_value = DOMPurify.sanitize(getInput(node).value || '', { ALLOWED_TAGS: [] });
        return sanitized_value.trim();
      },
      is_changed(value, _id, _column, node) {
        // called before save/close. Return true if new value differs from the original one
        // returning true will trigger saving changes, returning false will skip saving
        const sanitized_value = DOMPurify.sanitize(getInput(node).value || '', { ALLOWED_TAGS: [] });
        return sanitized_value !== value;
      },
      is_valid(_value, _id, _column, node) {
        // validate, changes will be discarded if the method returns false
        const sanitized_value = DOMPurify.sanitize(getInput(node).value || '', { ALLOWED_TAGS: [] });
        return !!sanitized_value?.trim?.()?.length;
      },
      hide() {
        const the_input = document.querySelector('#textInput');
        the_input?.blur?.();
        const task_id = the_input.getAttribute('task-id');
        const t = $g.value.getTask(task_id);
        if (t?.not_finalized && !t?.text)
          $g.value.isTaskExists(task_id) && $g.value.deleteTask(task_id);
      },
      save() {},
      focus() {},
    };

    $g.value.config.editor_types.resources = {
      show(id, _column, _config, placeholder) {
        // called when input is displayed, put html markup of the editor into placeholder
        // and initialize your editor if needed:
        openResourcesEditor(placeholder.getBoundingClientRect(), id);
      },

      is_changed(_value, _id, _column, _node) {
        // called before save/close. Return true if new value differs from the original one
        // returning true will trigger saving changes, returning false will skip saving
        return false;
      },

      is_valid(_value, _id, _column, _node) {
        // validate, changes will be discarded if the method returns false
        return true;
      },

      hide() {},
      save() {},
      focus() {},
      set_value() {},
      get_value() {},
    };

    let latest_value_of_date;
    let currently_editing_task;
    let column_name;
    $g.value.config.editor_types.pm_date = {
      show(id, column, _config, placeholder) {
        const task = $g.value.getTask(id);
        currently_editing_task = task;
        column_name = column.name;
        if (datepicker_tippy.value) {
          datepicker_tippy.value.destroy();
          datepicker_tippy.value = null;
          $g.value.ext.inlineEditors.hide();
        }
        if (!task[column_name])
          latest_value_of_date = null;
        else
          latest_value_of_date = new Date(task[column_name]);
        datepicker_tippy.value = useTippy(() => document.body, {
          appendTo: tippy_target.value,
          theme: 'pm-date-editor',
          content: h(VueDatePicker, {
            'modelValue': latest_value_of_date,
            'onUpdate:modelValue': (new_value) => {
              if (dayjs(new_value).isSame(latest_value_of_date, 'day'))
                latest_value_of_date = null;
              else
                latest_value_of_date = new_value;
              $g.value.ext.inlineEditors.save();
            },
            'disabled-dates': (date) => {
              if (!column_name.startsWith('custom_field_'))
                return !$g.value?.isWorkTime?.(date);
              return false;
            },
            'placeholder': 'Select a date',
            'ui': { calendarCell: 'w-8 h-8' },
            'format': 'yyyy-MM-dd',
            'is-24': false,
            'inline': true,
            'teleport': true,
            'autoApply': true,
            'arrowNavigation': true,
            'enableTimePicker': false,
          }),
          showOnCreate: true,
          arrow: false,
          interactive: true,
          animation: false,
          trigger: 'manual',
          placement: 'bottom-start',
          offset: [0, -30],
          hideOnClick: true,
          maxWidth: 539,
          getReferenceClientRect() {
            return placeholder.getBoundingClientRect();
          },
          onShow(instance) {
            onDatePickerShown(instance);
          },
        });
        waitForElement('.dp--arrow-btn-nav').then((element) => {
          element.focus();
        });
      },
      get_value(_id, _column, _node) {
        return latest_value_of_date;
      },
      is_changed() {
        if (
          (!latest_value_of_date && currently_editing_task[column_name])
          || (latest_value_of_date && !currently_editing_task[column_name])
        ) {
          return true;
        }
        return !dayjs(currently_editing_task[column_name]).isSame(latest_value_of_date, 'day');
      },
      is_valid() {
        return dayjs(latest_value_of_date).isValid();
      },
      hide() {
        datepicker_tippy.value.destroy();
        datepicker_tippy.value = null;
      },
      save() {},
      focus() {},
      set_value() {},
    };
  }

  async function updateCustomField(type, task_uid, field_name, field_value) {
    if (type === 'add_or_update') {
      await add_or_update_custom_field_value(
        {
          uids: task_uid,
          custom_fields: [
            {
              field: field_name,
              value: field_value,
            },
          ],
        },
      );
    }
    else if (type === 'clear') {
      await clear_custom_field_value(
        {
          uids: [task_uid],
          fields: [field_name],
        },
      );
    }
  }

  function handleCustomFieldSave(state, task) {
    try {
      is_mini_loading.value = true;
      const trimmed_field_name = state.columnName.replace('custom_field_', '');

      let field_value = DOMPurify.sanitize(state.newValue || '', { ALLOWED_TAGS: [] });
      if (state.newValue && ['number', 'money'].includes(active_schedule.value.custom_fields[trimmed_field_name].type))
        field_value = Number.parseFloat(state.newValue);
      else if (state.newValue && active_schedule.value.custom_fields[trimmed_field_name].type === 'date')
        field_value = dayjs(field_value).toISOString();

      const new_value = {
        ...active_schedule.value.activities[task.uid].custom_field_values,
        [trimmed_field_name]: field_value,
      };

      update_activity({
        uid: task.uid,
        custom_field_values: new_value,
        [state.columnName]: field_value,
      }, false, ['custom_field_values', state.columnName]);
      state.newValue = field_value;

      if (!isEqual(state.newValue, state.oldValue)) {
        if (!is_schedule_editable.value) {
          if (field_value)
            updateCustomField('add_or_update', task.uid, trimmed_field_name, field_value);
          else if (state.oldValue)
            updateCustomField('clear', task.uid, trimmed_field_name, field_value);
        }
        else {
          $g.value.refreshTask(task.id);
        }
      }
    }
    catch (error) {
      logger.error(error);
    }
    finally {
      is_mini_loading.value = false;
    }
  }

  function handleStartDateChangeForProjects(id, columnName, newValue) {
    const task = $g.value.getTask(id);
    if (task.type === $g.value.config.types.project && columnName === 'start_date') {
      $g.value.batchUpdate(() => {
        const days_to_add = dayjs(newValue).diff(dayjs(task[columnName]), 'day');
        $g.value.eachTask((subtask) => {
          subtask.start_date = $g.value.date.add(subtask.start_date, days_to_add, 'day');
          subtask.start_date = $g.value.getClosestWorkTime({
            dir: 'future',
            date: subtask.start_date,
            unit: $g.value.config.duration_unit,
          });
          subtask.end_date = $g.value.calculateEndDate(subtask.start_date, subtask.duration);
          $g.value.updateTask(subtask.id);
        }, id);
      });
    }
  }

  function setupInlineEditorEvents() {
    inline_editor_events.forEach((event) => {
      $g.value.ext.inlineEditors.detachEvent(event);
    });
    inline_editor_events = [];

    inline_editor_events.push(
      $g.value.ext.inlineEditors.attachEvent('onBeforeEditStart', (state) => {
        const task = $g.value.getTask(state.id);
        // Disables editing thru the inline editors if: the slide-over(active_task_uid) is present, the mini-loader is active, or the schedule is not editable and the column is not a custom field or a resources field
        // Disables editing 'resources' if the activity is not of the type 'TASK'
        // ALWAYS disables editing 'progress'
        // Disables editing 'duration' if the activity is not of the type 'TASK'
        // Disables editing all the fields except 'id' if the activity is of the type 'PROJECT'
        if (
          active_task_uid.value
          || is_mini_loading.value
          || (!is_schedule_editable.value
            && !state.columnName.startsWith('custom_field_')
            && state.columnName !== 'resources'
          )
          || (state.columnName === 'resources' && (!active_schedule.value.track_resources || task.type !== $g.value.config.types.task))
          || state.columnName === 'progress'
          || (state.columnName === 'weight' && task._original_type === $g.value.config.types.project)
          || (state.columnName === 'duration' && task.type !== $g.value.config.types.task)
        ) {
          if (is_mini_loading.value && !active_task_uid.value) {
            $toast({
              title: $t('Please wait'),
              text: $t('Your current action cannot be completed at this moment.'),
              type: 'warning',
            });
          }
          return false;
        }

        const modified_column_name = state.columnName.replace('custom_field_', '');
        if (active_schedule.value.custom_fields[modified_column_name]?.type === 'text') {
          if (!$g.value.getTask(state.id)[state.columnName])
            $g.value.getTask(state.id)[state.columnName] = '';
        }

        return true;
      }),
      $g.value.ext.inlineEditors.attachEvent('onEditStart', (editor_state) => {
        state.editor_state = editor_state;
        if (editor_state.columnName === 'progress') {
          const node = $g.value.ext.inlineEditors._placeholder.firstChild.firstChild;
          node.value = Math.round(Number.parseFloat(node.value) * 100);
        }
        else if (editor_state.columnName === 'weight') {
          const node = $g.value.ext.inlineEditors._placeholder.firstChild.firstChild;
          node.value = Number.parseFloat(node.value);
        }
        else if (editor_state.columnName === 'constraint_type') {
          const node = $g.value.ext.inlineEditors._placeholder.firstChild.firstChild;
          node && node.addEventListener('change', onConstraintSelectChange);
        }
      }),
      $g.value.ext.inlineEditors.attachEvent('onBeforeSave', (state) => {
        if (state.columnName === 'constraint_type') {
          const node = $g.value.ext.inlineEditors._placeholder.firstChild.firstChild;
          node && node.removeEventListener('change', onConstraintSelectChange);
        }

        const task = $g.value.getTask(state.id);
        if (state.columnName === 'progress') {
          state.newValue /= 100;
          if (state.newValue < 0 || state.newValue > 1)
            state.newValue = state.oldValue;
          if (task.type === $g.value.config.types.milestone && state.newValue < 1)
            state.newValue = 0;
        }

        else if (state.columnName === 'weight') {
          state.newValue = Number.parseFloat(Number.parseFloat(state.newValue).toFixed(2));
          if (state.newValue < 0 || state.newValue > 100)
            state.newValue = state.oldValue;
        }

        else if (state.columnName.startsWith('custom_field_')) {
          handleCustomFieldSave(state, task);
        }

        return true;
      }),
      $g.value.ext.inlineEditors.attachEvent('onSave', ({ id, columnName, newValue }) => {
        handleStartDateChangeForProjects(id, columnName, newValue);

        if ($g.value.autoSchedule && ['start_date', 'duration'].includes(columnName))
          $g.value.autoSchedule();
      }),
      $g.value.ext.inlineEditors.attachEvent('onEditEnd', () => {
        tippy('.weight-problem[data-tippy-content]');
      }),
    );
  }

  return {
    setupInlineEditors,
    setupInlineEditorEvents,
  };
}
