<script setup>
import { keyBy } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import AssetsGrid from '~/assets/components/assets-grid.vue';
import AssetsHierarchyList from '~/assets/components/assets-hierarchy-list.vue';
import AssetsMap from '~/assets/components/assets-map.vue';
import DuplicateAsset from '~/assets/components/duplicate-asset.vue';
import HawkSearchInput from '~/common/components/molecules/hawk-search-input.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useJsonRuleEngine } from '~/common/composables/json-engine.js';
import { sortData } from '~/common/utils/common.utils';

const { $t, auth_store, common_store, route, router } = useCommonImports();
const { filter_options, is_applying_filters, display_filters$, filtered_data, onApplyDisplayFilters } = useDisplayFilters();

const { loadJsonEngine, runJsonEngine } = useJsonRuleEngine();
loadJsonEngine();
const state = reactive({
  active_layout: 'grid',
  search: '',
  view: null,
});
const is_loading = computed(() => (is_applying_filters.value || common_store.asset_loading));

const view_items = [
  {
    uid: 'grid',
    leftSlot: IconHawkGridOne,
    action: 'grid',
    tooltip_text: $t('Grid view'),
  },
  {
    uid: 'map',
    leftSlot: IconHawkMarkerPinOne,
    action: 'map',
    tooltip_text: $t('Map view'),
  },
  {
    uid: 'hierarchy-list',
    leftSlot: IconHawkTableTwo,
    action: 'hierarchy-list',
    tooltip_text: $t('List view'),
  },

];

const hawk_menu_items = [
  {
    label: $t('Duplicate'),
    uid: 'duplicate',
    onClick: asset => onActionClicked('duplicate', asset),
  },
  {
    label: $t('Settings'),
    uid: 'settings',
    onClick: asset => onActionClicked('settings', asset),
  },
  {
    label: $t('Delete'),
    uid: 'delete',
    onClick: asset => onActionClicked('delete', asset),
  },
];
const sorted_data = computed(() => sortData(filtered_data.value, 'name', 'asc'));

const column_options = computed(() => {
  const custom_field_columns = common_store?.assets_custom_fields?.map(item => ({
    id: item.uid,
    accessorKey: item.uid,
    header: item.name,
  })) || [];
  return [
    {
      id: 'name',
      accessorKey: 'name',
      header: 'Asset Name',
      static: true,
    },
    {
      id: 'code',
      accessorKey: 'code',
      header: 'Asset code',
      static: true,
    },
    {
      id: 'address',
      accessorKey: 'address',
      header: 'Address',
      static: true,
    },
    ...custom_field_columns,
    {
      id: 'context_menu',
      accessorKey: 'context_menu',
      header: '',
      show_on_hover: 'true',
      static: true,
    },
  ];
});
const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({ component: HawkDeletePopup });

function assetDeleteHandler({ name, uid }) {
  patchOptions({
    attrs: {
      header: $t('Delete Asset'),
      content: `Are you sure you want to delete ${name || ''}? This action cannot be undone.`,
      onClose() {
        closeDeletePopup();
      },
      confirm: async () => {
        try {
          await common_store.update_data({
            type: 'delete',
            id: uid,
            service: 'assets',
            append_data: true,
            state_prop: 'assets_map',
            update_state: true,
          });
          closeDeletePopup();
        }
        catch (error) {
          logger.log('🚀 ~ confirm: ~ error:', error);
          $toast({
            title: 'Something went wrong',
            text: 'Please try again',
            type: 'error',
          });
        }
      },
    },
  });
  openDeletePopup();
}
const { open: openDuplicateModal, close: closeDuplicateModal, patchOptions: patchAssetOptions } = useModal({ component: DuplicateAsset });

function assetDuplicateHandler(asset) {
  patchAssetOptions({
    attrs: {
      item: asset,
      onClose() {
        closeDuplicateModal();
      },
    },
  });
  openDuplicateModal();
}

function changeActiveLayout(layout) {
  state.search = '';
  state.active_layout = layout;
}

async function onActionClicked(action, asset) {
  try {
    switch (action) {
      case 'view':
        router.push({ name: auth_store.check_split('account_settings_v2') ? 'account-settings-asset-general' : 'asset-settings-general', params: { asset_id: asset.uid } });
        break;
      case 'delete':
        assetDeleteHandler(asset);
        break;
      case 'settings':
        router.push({ name: auth_store.check_split('account_settings_v2') ? 'account-settings-asset-general' : 'asset-settings-general', params: { asset_id: asset.uid } });
        break;
      case 'duplicate':
        assetDuplicateHandler(asset);
        break;
    }
  }
  catch (error) {
    logger.info('🚀 ~ onActionClicked ~ error:', error);
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
  }
}

function useDisplayFilters() {
  const operator_mapping = {
    text: { data_type: 'text', operators: ['contains'] },
    number: { data_type: 'number', operators: ['isBetween'] },
    dropdown: { data_type: 'single_select', operators: ['isAnyOf'] },
    email: { data_type: 'text', operators: ['isEqualTo'] },
    phone: { data_type: 'text', operators: ['isEqualTo'] },
    money: { data_type: 'number', operators: ['isBetween'] },
    percentage: { data_type: 'number', operators: ['isBetween'] },
    date: { data_type: 'date', operators: ['between'] },
    checkbox: { data_type: 'multi_select', operators: ['containsAnyOf'] },
    radio: { data_type: 'single_select', operators: ['isEqualTo'] },
    planned_actual: { data_type: 'text', operators: ['isEqualTo'] },
    files: { data_type: 'attachments', operators: ['isEqualTo'] },
    members: { data_type: 'multi_select', option_type: 'members', operators: ['containsAnyOf'] },
    formula: { data_type: 'text', operators: ['contains'] },
    datetime: { data_type: 'date', operators: ['between'] },
    date_range: { data_type: 'date', operators: ['between'] },
  };
  const filter_options = computed(() => {
    return common_store?.assets_custom_fields?.map((field) => {
      return {
        uid: field.uid,
        name: field.name || 'NA',
        ...operator_mapping[field.type],
        options: field.config?.length ? field.config : null,
      };
    });
  });
  const display_filters$ = ref();
  function getJSONRules() {
    return {
      all: [
        {
          any: state.search
            ? [
                { fact: 'name', operator: 'stringContains', value: state.search },
                { fact: 'code', operator: 'stringContains', value: state.search },
              ]
            : [],
        },
        {
          all: (display_filters$.value?.filters || [])
            .map(item => ({
              fact: item.field,
              operator: item.operator,
              value: item.value,
            })),
        },
      ],
    };
  }

  const filtered_data = ref([]);
  const is_applying_filters = ref(true);
  const assets_facts_hash = computed(() => {
    return keyBy(common_store?.assets?.map((item) => {
      return {
        ...item,
        ...Object.keys(item.metadata).reduce((facts, key) => {
          facts[key] = item.metadata[key]?.value;
          return facts;
        }, {}),
      };
    }), 'uid');
  });
  async function onApplyDisplayFilters() {
    try {
      filtered_data.value = await runJsonEngine(getJSONRules(), assets_facts_hash.value, common_store?.assets);
      is_applying_filters.value = false;
    }
    catch (err) {
      is_applying_filters.value = false;
      logger.log(err);
    }
  }
  return {
    filter_options,
    display_filters$,
    is_applying_filters,
    onApplyDisplayFilters,
    filtered_data,
  };
}
</script>

<template>
  <div :class="{ 'pb-6': state.active_layout !== 'map' }">
    <div>
      <HawkPageHeader :title="`${$t('Assets')} (${sorted_data?.length || 0})`">
        <template #left>
          <HawkButtonGroup
            :items="view_items" :active_item="state.active_layout"
            icon
            size="md"
            @grid="changeActiveLayout('grid')"
            @map="changeActiveLayout('map')"
            @list="changeActiveLayout('list')"
            @hierarchy-list="changeActiveLayout('hierarchy-list')"
          />
        </template>
      </HawkPageHeader>
      <div class="inline-block md:flex items-center justify-between px-4 w-full sticky-header !z-20">
        <div v-if="is_loading" />
        <HawkDisplayFilters
          v-show="!is_loading"
          ref="display_filters$"
          :view_configuration="{
            service: 'core',
            feature: 'list_view',
            resource_type: 'asset',
            name: auth_store?.current_organization?.uid,
            store_key: `asset_list-${auth_store?.current_organization?.uid}`,
            modify_permissions: auth_store.check_permission('modify_assets', route.params.asset_id),
          }"
          :filter_options="keyBy(filter_options, 'uid')"
          :default_filters="filter_options?.slice(0, 4)"
          :is_save_view_toast="true"
          :field_modal_options="{
            texts: {
              heading: $t('Choose fields to filter'),
              left_heading: $t('Available filters'),
              right_heading: $t('Displayed filters'),
            },
          }"
          @apply="onApplyDisplayFilters"
        />

        <div class="flex gap-3 items-center justify-between">
          <HawkSearchInput
            v-model="state.search"
            :placeholder="$t('Search')"
            @update:model-value="onApplyDisplayFilters"
          />

          <HawkButton v-if="auth_store.check_permission('create_assets', $route.params.asset_id)" @click="$router.push({ name: 'create-asset' })">
            <IconHawkPlus class="text-white" />
            {{ $t('New Asset') }}
          </HawkButton>
        </div>
      </div>
    </div>
    <hawk-loader v-if="is_loading" />
    <div v-else-if="!sorted_data.length">
      <HawkIllustrations :type="state.search ? 'no-results' : 'no-data'" for="assets-list" />
    </div>
    <div v-else-if="sorted_data.length" class="m-4">
      <AssetsGrid
        v-if="state.active_layout === 'grid'"
        :assets="sorted_data"
        :hawk_menu_items="hawk_menu_items"
        @action-click="onActionClicked"
      />
      <AssetsMap v-else-if="state.active_layout === 'map'" :assets="sorted_data" />
      <AssetsHierarchyList
        v-else-if="state.active_layout === 'hierarchy-list'"
        :assets="sorted_data"
        :column_options="column_options"
        :search="state.search"
        :hawk_menu_items="hawk_menu_items"
        @action-click="onActionClicked"
      />
    </div>
  </div>
</template>

<style>
.asset-img-placeholder {
  background-color: #e2e2e2;
  opacity: 0.2;
  background-image:  linear-gradient(135deg, #b8b8b9 25%, transparent 25%), linear-gradient(225deg, #b8b8b9 25%, transparent 25%), linear-gradient(45deg, #b8b8b9 25%, transparent 25%), linear-gradient(315deg, #b8b8b9 25%, #e2e2e2 25%);
  background-position:  4px 0, 4px 0, 0 0, 0 0;
  background-size: 4px 4px;
  background-repeat: repeat;
}
</style>
