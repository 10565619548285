<script setup>
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const $t = inject('$t');

const project_management_store = useProjectManagementStore();

const { active_view } = storeToRefs(project_management_store);

const { modify_config, set_view_dirtiness } = project_management_store;

const wbs_level = computed(() => active_view.value.data.wbs_level);
const wbs_level_max = computed(() => active_view.value.data.wbs_level_max);

function setWbsLevel(level) {
  modify_config({ key: 'wbs_level', value: level });
  set_view_dirtiness(true);
}

const level_menu_items = computed(() => {
  if (wbs_level_max.value === Number.MAX_SAFE_INTEGER)
    return [];
  const arr = [];
  for (let i = 1; i <= wbs_level_max.value; i++)
    arr.push({
      uid: i,
      label: `${$t('Level')} ${i}`,
      on_click: () => setWbsLevel(i),
    });

  return arr;
});
</script>

<template>
  <hawk-menu
    :key="`${wbs_level_max}-${wbs_level}`"
    :items="level_menu_items"
    :active_item="wbs_level"
    additional_dropdown_classes="right-full !-top-0 mr-4 !mt-0 !bottom-auto"
    additional_trigger_classes="!ring-0"
    position="bottom-left"
  >
    <template #trigger>
      <div class="flex items-center h-9 hover:bg-gray-50 rounded-lg py-2 px-3 -ml-3 w-[232px]">
        <div class="text-sm text-ellipsis overflow-hidden relative whitespace-nowrap">
          <span class="text-gray-500">{{ $t('Levels') }}: </span>
          <span v-if="(wbs_level_max === wbs_level) || (wbs_level === Number.MAX_SAFE_INTEGER)" class="font-medium">{{ $t('All levels') }}</span>
          <span v-else class="font-medium">{{ $t('Up to level') }} {{ wbs_level }}</span>
        </div>
        <IconHawkChevronRight class="ml-auto" />
      </div>
    </template>
  </hawk-menu>
</template>
