<script setup>
import { storeToRefs } from 'pinia';
import { cloneDeep, flatten, isEmpty, isEqual, uniqBy } from 'lodash-es';
import { useRoute } from 'vue-router';
import { useProjectManagementStore } from '~/project-management/store/pm.store';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';

const props = defineProps({
  field_uids: {
    type: Object,
    required: false,
  },
  is_loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close', 'update']);

const $t = inject('$t');
const $services = inject('$services');

const project_management_store = useProjectManagementStore();
const { active_task, active_task_auto_progress_cache, is_fullscreen } = storeToRefs(project_management_store);

const route = useRoute();

const vueform_columns = {
  default: {
    container: 12,
    label: 3,
    wrapper: 9,
  },
  sm: {
    label: 4,
  },
  md: {
    label: 4,
  },
  lg: {
    label: 4,
  },
};

const state = reactive({
  fields: {
    list: [],
  },
  workflows: [],
  all_fields: [],
  name_map: {},
  selected_fields: {
    data: [],
  },
  reload_count: 0,
});

const field_uids = computed(() => props.field_uids);

onMounted(() => {
  if (active_task_auto_progress_cache.value) {
    state.workflows = active_task_auto_progress_cache.value.workflows;
    state.all_fields = active_task_auto_progress_cache.value.all_fields;
    state.name_map = active_task_auto_progress_cache.value.name_map;

    const field_uids = Object.keys(props.field_uids ?? {});
    state.selected_fields.data = active_task_auto_progress_cache.value?.selected_fields?.data ?? field_uids;
    state.reload_count++;
    state.fields.list = [];
    for (const field of state.all_fields)
      if (field_uids.includes(field.uid))
        state.fields.list.push({
          name: field.uid,
          label: field.name,
          weights: Math.round(props.field_uids[field.uid] * 100),
        });
  }
});

watch(field_uids, (value) => {
  const field_uids = Object.keys(value ?? {});
  state.selected_fields.data = field_uids;
  state.reload_count++;
  state.fields.list = [];
  for (const field of state.all_fields)
    if (field_uids.includes(field.uid))
      state.fields.list.push({
        name: field.uid,
        label: field.name,
        weights: Math.round(value[field.uid] * 100),
      });
});

watch(() => state.selected_fields.data, (newVal, oldVal) => {
  if (!isEqual(newVal, oldVal) && newVal.length < oldVal.length) {
    updateRows(newVal);
    emitUpdates();
  }
});

async function getWorkflows() {
  const { data } = await $services.terra_workflow.getAll({
    query: {
      asset: route.params.asset_id,
    },
  });

  state.name_map = {};

  state.workflows = data.map((wf) => {
    const child = uniqBy(
      flatten(Object.values(wf.data || {}).map(item => item.fields)),
      f => f.uid,
    );
    state.name_map[wf.uid] = wf.name;
    return {
      name: wf.name,
      uid: wf.uid,
      fields: child,
    };
  });

  state.all_fields = state.workflows.map(flow => flow.fields).flat();
  state.all_fields.forEach((field) => {
    state.name_map[field.uid] = field.name;
  });

  project_management_store.auto_update_progress_cache[active_task.value.uid] = {
    ...active_task_auto_progress_cache.value,
    workflows: state.workflows,
    all_fields: state.all_fields,
    selected_fields: state.selected_fields,
    name_map: state.name_map,
  };
}

function updateRows(new_selected_fields) {
  state.selected_fields.data = new_selected_fields;

  // remove old filters
  const rows = state.fields.list.filter(f => new_selected_fields.includes(f.name));

  const existing_names = rows.map(f => f.name);

  // add new fields
  new_selected_fields.forEach((new_field) => {
    if (!existing_names.includes(new_field))
      rows.push({
        name: new_field,
        label: state.name_map[new_field],
        weights: null,
      });
  });

  state.fields.list = [...rows];
}

function emitUpdates() {
  const cpy = [...state.fields.list];
  emit('update', cpy);
}

if (isEmpty(active_task_auto_progress_cache.value))
  getWorkflows();

function updateFieldsCache() {
  project_management_store.auto_update_progress_cache[active_task.value.uid] = cloneDeep({
    ...active_task_auto_progress_cache.value,
    workflows: state.workflows,
    all_fields: state.all_fields,
    name_map: state.name_map,
    selected_fields: state.selected_fields,
  });
}

defineExpose({
  updateFieldsCache,
});
</script>

<template>
  <Vueform
    v-model="state.selected_fields"
    size="sm"
    sync
    :columns="vueform_columns"
    :display-errors="false"
  >
    <HawkTreeSelect
      :key="state.workflows.length + state.reload_count"
      :options="{
        name: 'data',
        appendTo: '#pm-update-auto-progress-modal',
        placeholder: $t('Choose one/more construction activities'),
        description: $t('Select atleast one construction activity to automatically sync the completion progress'),
        loading: props.is_loading,
      }"
      :data="state.workflows"
      children_key="fields"
      :label="$t('Activities')"
      label_key="name"
      value_key="uid"
      select_type="LEAF_PRIORITY"
      :initial_state="state.selected_fields.data"
      :rules="['required']"
      :messages="{ required: 'This field is required.' }"
      @updateForm="updateRows($event); emitUpdates();"
    />
  </Vueform>
</template>
