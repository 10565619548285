<script setup>
import AlertCircle from '~icons/hawk/alert-circle?raw';
import AlertTriangle from '~icons/hawk/alert-triangle?raw';
import CheckCircle from '~icons/hawk/check-circle?raw';
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { changeIconDimensions } from '~/common/utils/common.utils';
import { import_fusioncharts } from '~/common/utils/package.utils';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const $t = inject('$t');
const $date = inject('$date');

const project_management_store = useProjectManagementStore();

const { active_task, activity_details_page } = storeToRefs(project_management_store);

const { get_activity_logs, get_activity_progress_history, get_activity_field_history } = project_management_store;

const IconCheckCircle = changeIconDimensions(CheckCircle, 16, 16)?.replace('currentColor', 'white');
const IconAlertTriangle = changeIconDimensions(AlertTriangle, 16, 16)?.replace('currentColor', 'white');
const IconAlertCircle = changeIconDimensions(AlertCircle, 16, 16)?.replace('currentColor', 'white');

const state = reactive({
  is_loading: true,
  fc_instance: null,
  progress_logs_data: [],
  progress_history_data: [],
  field_history_data: [],
  field_history_start_date: dayjs(active_task.value.start_date).startOf('month'),
  field_history_end_date: dayjs(active_task.value.start_date).add(1, 'month').endOf('month'),
});

// TODO: i18n (this entire file)
const notch_data = computed(() => {
  const field_history_overall = state.field_history_data.overall;
  const overall_data = {
    group_name: $t('Overall progress'),
    info: [
      {
        title: $t('Average work rate'),
        value: `${field_history_overall?.avg_work_rate || ''} ${field_history_overall?.units || '-'}`,
      },
      {
        title: $t('Estimated finish date'),
        value: `${field_history_overall?.est_finish_date ? dayjs(field_history_overall?.est_finish_date, 'YYYY-MM-DD').format('DD MMM YYYY') : '-'}`,
      },
    ],
    data_points: computeDataPoints(field_history_overall?.history, field_history_overall?.units),
  };

  if (!state.field_history_data.fields)
    return [overall_data];
  const fields_data = Object.values(state.field_history_data.fields).map((field_history_for_fields) => {
    return {
      group_name: field_history_for_fields.name,
      info: [
        {
          title: $t('Average work rate'),
          value: `${field_history_for_fields.avg_work_rate || ''} ${field_history_for_fields.units || '-'}`,
        },
        {
          title: $t('Estimated finish date'),
          value: `${field_history_for_fields?.est_finish_date ? dayjs(field_history_for_fields?.est_finish_date, 'YYYY-MM-DD').format('DD MMM YYYY') : '-'}`,
        },
      ],
      data_points: computeDataPoints(field_history_for_fields.history, field_history_for_fields?.units),
    };
  });

  return [overall_data, ...fields_data];
});

const logs = computed(() => {
  return state.progress_logs_data.map(log => ({
    user: log?.owner?.uid,
    from: Math.round(log.data.old.progress * 100),
    to: Math.round(log.data.new.progress * 100),
    data_date: log.data.new.data_date,
  }));
});

function generateTooltipContent(current_date, work_done, work_required, unit, notch_type) {
  const dateFormatted = dayjs(current_date).format('ddd, MMM DD, YYYY');
  const difference = (work_done - work_required).toFixed(2);
  const contentMap = {
    'green-outlined': `
      <div class="flex items-center gap-2">
        <div class="[&>svg]:fill-success-500">${IconCheckCircle}</div>
        <div class="flex flex-col gap-[3px]">
          <div class="text-xs font-semibold">${dateFormatted}</div>
          <div class="text-xs">${work_done} ${unit} (${difference} ${$t('more than required')})</div>
        </div>
      </div>
    `,
    'green-filled': `
      <div class="flex items-center gap-2">
        <div class="[&>svg]:fill-success-500">${IconCheckCircle}</div>
        <div class="flex flex-col gap-[3px]">
          <div class="text-xs font-semibold">${dateFormatted}</div>
          <div class="text-xs">${work_done} ${unit} (${difference} ${$t('more than required')})</div>
        </div>
      </div>
    `,
    'yellow-outlined': `
      <div class="flex items-center gap-2">
        <div class="[&>svg]:fill-warning-500">${IconAlertCircle}</div>
        <div class="flex flex-col gap-[3px]">
          <div class="text-xs font-semibold">${dateFormatted}</div>
          <div class="text-xs">${work_done} ${unit} (${(-difference).toFixed(2)} ${$t('less than required')})</div>
        </div>
      </div>
    `,
    'yellow-filled': `
      <div class="flex items-center gap-2">
        <div class="[&>svg]:fill-warning-500">${IconAlertCircle}</div>
        <div class="flex flex-col gap-[3px]">
          <div class="text-xs font-semibold">${dateFormatted}</div>
          <div class="text-xs">${work_done} ${unit} (${(-difference).toFixed(2)} ${$t('less than required')})</div>
        </div>
      </div>
    `,
    'red-filled': `
      <div class="flex items-center gap-2">
        <div class="[&>svg]:fill-error-500">${IconAlertTriangle}</div>
        <div class="flex flex-col gap-[3px]">
          <div class="text-xs font-semibold">${dateFormatted}</div>
          <div class="text-xs">${work_done} ${unit} (${(-difference).toFixed(2)} ${$t('less than required')})</div>
        </div>
      </div>
    `,
    'default': `
      <div class="flex items-center gap-2">
        <div class="[&>svg]:fill-gray-400">${IconAlertCircle}</div>
        <div class="flex flex-col gap-[3px]">
          <div class="text-xs font-semibold">${dateFormatted}</div>
          <div class="text-xs">${$t('No data')}</div>
        </div>
      </div>
    `,
  };

  return {
    content: contentMap[notch_type],
    allowHTML: true,
    placement: 'bottom',
  };
}

function determineNotchType(current_date, work_done, work_required) {
  if (current_date.isBefore(dayjs(active_task.value.start_date))) {
    if (work_done >= work_required && work_done > 0)
      return 'green-outlined';
    else if (work_done < work_required)
      return 'yellow-outlined';
    else
      return 'default';
  }
  else if (work_done >= work_required && work_done > 0) {
    return 'green-filled';
  }
  else if (work_done === 0) {
    return 'red-filled';
  }
  else {
    return 'yellow-filled';
  }
}

function computeDataPoints(history_data_points, unit) {
  let current_date = state.field_history_start_date;
  const data_points = [];
  while (!current_date.isSame(state.field_history_end_date, 'day')) {
    const data_point = {
      notch_type: 'default',
      tooltip: generateTooltipContent(current_date, 0, 0, unit, 'default'),
    };

    const history = history_data_points?.[current_date.format('YYYY-MM-DD')];
    if (history) {
      const { work_done, work_required } = history;
      data_point.notch_type = determineNotchType(current_date, work_done, work_required);
      data_point.tooltip = generateTooltipContent(current_date, work_done, work_required, unit, data_point.notch_type);
    }

    data_points.push(data_point);
    current_date = current_date.add(1, 'day');
  }

  return data_points;
}

async function getData() {
  state.is_loading = true;
  state.progress_logs_data = await get_activity_logs(active_task.value.uid);
  state.progress_history_data = await get_activity_progress_history(active_task.value.uid);
  state.field_history_data = await get_activity_field_history(active_task.value.uid);
  const progress_logs = state.progress_logs_data.reduce((acc, curr) => {
    const { data_date, progress } = curr.data.new;
    acc.push([
      dayjs(data_date).format('DD-MMM-YY'),
      $t('Actual'),
      Math.round(progress * 100),
    ]);
    return acc;
  }, []);

  const progress_history = state.progress_history_data.reduce(
    (acc, curr) => {
      const data_date = dayjs(curr[0]);
      acc.push([
        data_date.format('DD-MMM-YY'),
        $t('Scheduled'),
        Math.round(curr[1] * 100),
      ]);
      return acc;
    },
    [],
  );

  let dates = [];
  const progress_logs_data = progress_logs.reduce((arr, curr) => {
    if (!dates.includes(curr[0])) {
      dates.push(curr[0]);
      arr.push(curr);
    }
    return arr;
  }, []);

  if (state.progress_logs_data.length) {
    const data = state.progress_logs_data[
      state.progress_logs_data.length - 1
    ];
    const { data_date, progress } = data.data.old;

    if (data_date) {
      progress_logs_data.unshift([
        dayjs(data_date).format('DD-MMM-YY'),
        $t('Actual'),
        Math.round(progress * 100),
      ]);
    }
  }

  dates = [];
  const progress_history_data = progress_history.reduce((arr, curr) => {
    if (!dates.includes(curr[0])) {
      dates.push(curr[0]);
      arr.push(curr);
    }
    return arr;
  }, []);

  const schema = [
    {
      name: 'Time',
      type: 'date',
      format: '%d-%b-%y',
    },
    {
      name: 'Type',
      type: 'string',
    },
    {
      name: 'Progress',
      type: 'number',
    },
  ];

  const data = [...progress_logs_data, ...progress_history_data];

  const { FusionCharts } = await import_fusioncharts();
  const data_store = new FusionCharts.DataStore();
  const data_source = {
    chart: {},
    data: data_store.createDataTable(data, schema),
    navigator: {
      enabled: 0,
    },
    series: 'Type',
    xaxis: {
      plot: 'Time',
      timemarker: [
        {
          start: dayjs(active_task.value.data_date).format('DD-MMM-YY'),
          timeformat: '%d-%b-%y',
          type: 'full',
          label: $t('Data date'),
        },
      ],
    },
    yaxis: [
      {
        plot: [
          {
            value: 'Progress',
            connectnulldata: true,
            min: '0',
            max: '100',
            format: {
              suffix: '%',
            },
            style: {
              'plot.null': {
                'stroke-dasharray': 'none',
              },
            },
          },
        ],
        title: $t('Progress'),
      },
    ],
  };
  state.is_loading = false;
  return data_source;
}

async function plotChart(data_source) {
  const { VueFusionChartsComponent, FusionCharts, TimeSeries } = await import_fusioncharts();

  VueFusionChartsComponent(FusionCharts, TimeSeries);
  state.fc_instance = new FusionCharts({
    type: 'timeseries',
    renderAt: 'progress-history-chart',
    width: '700',
    height: '350',
    dataSource: data_source,
  });
  state.fc_instance.render();
}

function handleRange(value) {
  state.field_history_start_date = state.field_history_start_date.add(value, 'month').startOf('month');
  state.field_history_end_date = state.field_history_end_date.add(value, 'month').endOf('month');
}

watch(() => active_task.value.uid, async () => {
  if (state.fc_instance) {
    const data_source = await getData();
    if (!state.progress_logs_data?.length && !state.progress_history_data?.length)
      return;
    state.fc_instance.setJSONData(data_source);
    state.fc_instance.render();
  }
  else {
    const data_source = await getData();
    if (!state.progress_logs_data?.length && !state.progress_history_data?.length)
      return;
    await plotChart(data_source);
  }
}, { immediate: true });
</script>

<template>
  <div class="mt-4 px-6">
    <HawkButton class="-ml-2" type="text" @click="activity_details_page = ''">
      <IconHawkChevronLeft />
      {{ $t('Back to details') }}
    </HawkButton>
    <div class="w-[700px] min-w-[700px]">
      <hawk-loader v-if="state.is_loading" />
      <div v-else-if="!state.progress_logs_data?.length && !state.progress_history_data?.length" id="progress-history-chart" class="text-xs flex items-center justify-center h-32">
        {{ $t('Nothing to show') }}
      </div>
      <div v-else id="progress-history-chart" />
    </div>
    <template v-if="!state.is_loading">
      <div class="flex justify-between mb-3">
        <div class="text-md font-semibold text-gray-900">
          {{ $t('Progress') }}
        </div>
        <div class="text-sm font-medium text-gray-600 flex gap-3">
          <IconHawkChevronLeft class="cursor-pointer" @click="handleRange(-1)" />
          {{ dayjs(state.field_history_start_date).format('DD MMM YYYY') }}
          &ndash;
          {{ dayjs(state.field_history_end_date).format('DD MMM YYYY') }}
          <IconHawkChevronRight class="cursor-pointer" @click="handleRange(1)" />
        </div>
      </div>
      <div class="border border-gray-200 p-4 rounded-lg">
        <HawkHeatmap
          :notch-data="notch_data"
        />
      </div>
    </template>
    <div v-if="logs.length" class="mt-6">
      <div class="text-md font-semibold text-gray-900 mb-2">
        {{ $t('Timeline') }}
      </div>
      <div
        v-for="log of logs"
        :key="log.uid"
        class="w-full flex items-center justify-between py-3 border-b border-solid border-gray-200 text-sm text-gray-600"
      >
        <div class="flex items-center">
          <template v-if="log.user">
            <HawkMembers
              :members="log.user"
              class="mr-1" type="badge"
            />
            {{ $t('changed progress') }}
          </template>
          <template v-else>
            {{ $t('Progress updated') }}
          </template>
          <span class="ml-1">{{ $t('from') }}</span>
          <span class="ml-1 font-semibold">{{ log.from }}%</span>
          <span class="ml-1">{{ $t('to') }}</span>
          <span class="ml-1 font-semibold">{{ log.to }}%</span>
        </div>
        <div>
          {{ $date(log.data_date, "DATE_MED") }}
        </div>
      </div>
    </div>
  </div>
</template>
