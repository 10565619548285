<script setup>
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const props = defineProps({
  attachments: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['add-attachment', 'remove-attachment']);

const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const { active_task } = storeToRefs(project_management_store);
const { update_activity_backend, update_activity, set_pm_comments_and_attachments } = project_management_store;

const state = reactive({
  are_attachments_open: true,
  is_refetching_attachments: false,
});

async function removeAttachment(attachment) {
  update_activity({
    uid: active_task.value.uid,
    attachments: active_task.value.attachments.filter(a => a.uid !== attachment.uid),
  }, false, ['attachments']);
  const body = { attachments: { delete: [attachment.uid] } };
  const data = await update_activity_backend(active_task.value?.uid, body);
  data.data.forEach((item) => {
    update_activity(item, true);
  });
  await set_pm_comments_and_attachments(false, true);
}

async function updateAttachmentNotes(payload) {
  update_activity({
    uid: active_task.value.uid,
    attachments: active_task.value.attachments.map((a) => {
      if (a.uid === payload.uid) {
        return {
          ...a,
          note: payload.notes,
        };
      }

      return a;
    }),
  }, false, ['attachments']);
  const body = { attachments: { update: [{ uid: payload.uid, note: payload.notes }] } };
  const data = await update_activity_backend(active_task.value?.uid, body);
  data.data.forEach((item) => {
    update_activity(item, true);
  });
  await set_pm_comments_and_attachments(false, true);
}
</script>

<template>
  <div v-if="props.attachments.length" id="attachments" class="py-4">
    <div class="flex items-center gap-2">
      <div class="flex items-center cursor-pointer" @click="state.are_attachments_open = !state.are_attachments_open">
        <IconHawkChevronDown v-if="state.are_attachments_open" class="inline" />
        <IconHawkChevronRight v-else class="inline" />
        <span class="ml-1 text-sm font-semibold text-gray-900">
          {{ $t('Attachments') }}
        </span>
      </div>
      <div class="flex items-center justify-center w-5 h-5 rounded-full cursor-pointer bg-primary-50 hover:bg-primary-100" @click="emit('add-attachment')">
        <IconHawkPlus class="w-3 h-3 text-primary-700" />
      </div>
    </div>
    <div v-if="state.are_attachments_open" class="mt-2 ml-4">
      <HawkAttachmentsGrid
        :items="attachments"
        :enable_description="true"
        parent_rect_id="attachments"
        @delete="removeAttachment"
        @update-notes="updateAttachmentNotes"
      >
        <template #notes_text="{ item }">
          <div class="text-xs text-wrap font-regular text-gray-900 max-w-[142px] text-center mt-1" :class="{ '!text-gray-500 italic': !item.notes }">
            {{ item.notes ?? $t('No description') }}
          </div>
        </template>
      </HawkAttachmentsGrid>
    </div>
  </div>
</template>
