<script setup>
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useModal } from 'vue-final-modal';
import { groupBy } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import { highlightElement, waitForElement } from '~/common/utils/common.utils';
import { useAuthStore } from '~/auth/stores/auth.store';
import HawkDatePickerModal from '~/common/components/organisms/hawk-datepicker-modal.vue';

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

dayjs.extend(isBetween);

const $t = inject('$t');
const $date = inject('$date');

const project_management_store = useProjectManagementStore();
const auth_store = useAuthStore();
const { set_active_task_uid } = project_management_store;
const { $g, active_schedule_data, pm_attachments, is_fullscreen } = storeToRefs(project_management_store);

const state = reactive({
  filtered_attachments: [],
  custom_range: [],
  left_menu_selection: 'all_attachments',
  right_menu_selection: 'all_time',
});

const date_range_modal = useModal({
  component: HawkDatePickerModal,
  attrs: {
    onClose() {
      state.right_menu_selection = 'all_time';
      date_range_modal.close();
    },
    onOkay(range) {
      state.custom_range = range;
      state.filtered_attachments = pm_attachments.value.filter(attachment => dayjs(attachment.created_at).isBetween(dayjs(range[0]), dayjs(range[1]), 'day', '[]'));
      date_range_modal.close();
    },
  },
});

const grouped_attachments = computed(() => {
  return groupBy(state.filtered_attachments, 'task_id');
});

async function onClick(activity_id) {
  const activity_uid = $g.value.getTask(activity_id).uid;
  set_active_task_uid(activity_uid);
  try {
    await waitForElement('#attachments').then((element) => {
      highlightElement(element, ['!-ml-3', '!pl-3', '!-mr-3', '!pr-3']);
    });
  }
  catch (error) {
    logger.error('Error while waiting for attachments element', error);
  }
}

const left_menu_items = computed(() => {
  return [
    {
      label: $t('All attachments'),
      uid: 'all_attachments',
      on_click: () => {
        state.filtered_attachments = pm_attachments.value;
        state.left_menu_selection = 'all_attachments';
      },
    },
    {
      label: $t('Uploaded by me'),
      uid: 'uploaded_by_me',
      on_click: () => {
        state.filtered_attachments = pm_attachments.value.filter((attachment) => {
          return attachment.owner?.uid === auth_store?.logged_in_user_details?.user_id;
        });
        state.left_menu_selection = 'uploaded_by_me';
      },
    },
    {
      label: $t('Assigned to me'),
      uid: 'assigned_to_me',
      on_click: () => {
        state.filtered_attachments = pm_attachments.value.filter((attachment) => {
          return $g.value.getTask(attachment.task_id)?.assignees?.includes?.(auth_store?.logged_in_user_details?.user_id);
        });
        state.left_menu_selection = 'assigned_to_me';
      },
    },
  ];
});

const right_menu_items = computed(() => {
  return [
    {
      label: $t('All time'),
      uid: 'all_time',
      on_click: () => {
        state.filtered_attachments = pm_attachments.value;
        state.right_menu_selection = 'all_time';
      },
    },
    {
      label: $t('Today'),
      uid: 'today',
      on_click: () => {
        state.filtered_attachments = pm_attachments.value.filter(attachment => dayjs(attachment.created_at).isSame(dayjs(), 'day'));
        state.right_menu_selection = 'today';
      },
    },
    {
      label: $t('Yesterday'),
      uid: 'yesterday',
      on_click: () => {
        state.filtered_attachments = pm_attachments.value.filter(attachment => dayjs(attachment.created_at).isSame(dayjs().subtract(1, 'day'), 'day'));
        state.right_menu_selection = 'yesterday';
      },
    },
    {
      label: $t('This week'),
      uid: 'this_week',
      on_click: () => {
        state.filtered_attachments = pm_attachments.value.filter(attachment => dayjs(attachment.created_at).isSame(dayjs(), 'week'));
        state.right_menu_selection = 'this_week';
      },
    },
    {
      label: $t('Last week'),
      uid: 'last_week',
      on_click: () => {
        state.filtered_attachments = pm_attachments.value.filter(attachment => dayjs(attachment.created_at).isSame(dayjs().subtract(1, 'week'), 'week'));
        state.right_menu_selection = 'last_week';
      },
    },
    {
      label: $t('This month'),
      uid: 'this_month',
      on_click: () => {
        state.filtered_attachments = pm_attachments.value.filter(attachment => dayjs(attachment.created_at).isSame(dayjs(), 'month'));
        state.right_menu_selection = 'this_month';
      },
    },
    {
      label: $t('Last month'),
      uid: 'last_month',
      on_click: () => {
        state.filtered_attachments = pm_attachments.value.filter(attachment => dayjs(attachment.created_at).isSame(dayjs().subtract(1, 'month'), 'month'));
        state.right_menu_selection = 'last_month';
      },
    },
    {
      label: $t('Custom'),
      uid: 'custom',
      on_click: () => {
        state.right_menu_selection = 'custom';
        date_range_modal.patchOptions({
          attrs: {
            options: { teleportTo: is_fullscreen.value ? '#pm-fullscreen-container' : 'body' },
          },
        });
        date_range_modal.open();
      },
    },
  ];
});

watchEffect(() => {
  if (props.active === true) {
    const app = document.getElementById('app');
    setTimeout(() => {
      app.removeAttribute('inert');
    }, 100);

    state.filtered_attachments = pm_attachments.value;
  }
});

watch(() => props.active, () => {
  if (props.active) {
    state.left_menu_selection = 'all_attachments';
    state.right_menu_selection = 'all_time';
  }
});
</script>

<template>
  <Teleport :to="is_fullscreen ? '#pm-fullscreen-container' : 'body'">
    <Transition name="sidebar-slide">
      <div v-if="active" class="slide-over fixed bg-white right-0 inset-y-0 z-[1000] w-[460px]">
        <div class="relative h-full flex flex-col bg-white shadow-xl flex-1">
          <div class="mx-6 my-4 flex justify-between items-center">
            <div class="text-md font-semibold text-gray-900">
              {{ $t('Attachments') }}
            </div>
            <HawkButton icon type="text" class="!h-10 !w-10 ml-1" @click="emit('close')">
              <IconHawkXClose class="text-gray-500" />
            </HawkButton>
          </div>
          <hr class="border-gray-300">
          <div class="px-6 py-3 scrollbar">
            <div class="flex justify-between">
              <HawkMenu
                class="!border-0 -ml-3 mb-3"
                :items="left_menu_items"
                additional_trigger_classes="!ring-0"
                additional_dropdown_classes="w-[200px]"
                position="fixed"
              >
                <template #trigger="{ open }">
                  <HawkButton type="text" size="xs">
                    {{ left_menu_items.find((menu_item) => menu_item.uid === state.left_menu_selection).label }}
                    <IconHawkChevronUp v-if="open" />
                    <IconHawkChevronDown v-else />
                  </HawkButton>
                </template>
              </HawkMenu>
              <HawkMenu
                class="!border-0 -ml-3 mb-3"
                :items="right_menu_items"
                additional_trigger_classes="!ring-0"
                additional_dropdown_classes="w-[200px]"
                position="fixed"
              >
                <template #trigger="{ open }">
                  <HawkButton
                    v-tippy="state.right_menu_selection === 'custom' ? `${$date(state.custom_range[0], 'YYYY-MM-DD')} ${$t('to')} ${$date(state.custom_range[1], 'YYYY-MM-DD')}` : ''"
                    type="text"
                    size="xs"
                  >
                    {{ right_menu_items.find((menu_item) => menu_item.uid === state.right_menu_selection).label }}
                    <IconHawkChevronUp v-if="open" />
                    <IconHawkChevronDown v-else />
                  </HawkButton>
                </template>
              </HawkMenu>
            </div>
            <div class="flex flex-wrap">
              <HawkIllustrations
                v-if="!pm_attachments?.length"
                type="no-data"
                for="attachments"
                variant="mini"
              />
              <div
                v-for="([activity_id, activity_attachments], index) in Object.entries(grouped_attachments)"
                :key="activity_attachments"
                class="w-full"
              >
                <div class="mb-2 text-sm text-gray-400 cursor-pointer font-semibold hover:text-gray-600 hover:underline hover:underline-offset-4" @click="onClick(activity_id)">
                  #{{ $g.getWBSCode($g.getTask(activity_id)) }} • {{ $g.getTask(activity_id).name }}
                </div>
                <HawkAttachmentsGrid
                  class="mt-4"
                  :items="activity_attachments.length > 6 ? activity_attachments.slice(0, 6) : activity_attachments"
                  :image_dimensions="[120, 120]"
                  :show_delete="false"
                  variant="small"
                />
                <div
                  v-if="activity_attachments.length > 6"
                  class="text-sm font-semibold text-primary-700 flex items-center gap-1 pt-2 cursor-pointer w-fit"
                  @click="onClick(activity_id)"
                >
                  {{ activity_attachments.length - 6 }} {{ $t('more') }}
                  <IconHawkChevronRight class="inline" />
                </div>
                <hr v-if="index < Object.entries(grouped_attachments).length - 1" class="border-gray-100 border-b-2 -mx-6 my-4">
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" scoped>
.sidebar-slide-enter-active,
.sidebar-slide-leave-active {
  transition: transform 0.5s ease;
}

.sidebar-slide-enter-from,
.sidebar-slide-leave-to {
  transform: translateX(100%);
}
</style>
