<script setup>
import { onKeyStroke } from '@vueuse/core';
import { cloneDeep } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import PmEditCustomField from '~/project-management/components/pm-edit-custom-field.vue';
import { getGanttColumns } from '~/project-management/constants/pm-gantt-column';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const emit = defineEmits(['close']);

const $t = inject('$t');
const $toast = inject('$toast');

const project_management_store = useProjectManagementStore();
const { set_view_dirtiness, modify_config, create_update_or_delete_custom_field } = project_management_store;
const { active_schedule, active_view, is_fullscreen, is_schedule_editable } = storeToRefs(project_management_store);

const state = reactive({
  all_columns: [],
  active_columns: [],
  temporary_active_columns: [],
  active_columns_saved: [],
  is_loading_items: false,
});

const new_field_modal = useModal({
  component: PmEditCustomField,
  attrs: {
    onClose() {
      new_field_modal.close();
    },
  },
});

const delete_popup = useModal({
  component: HawkDeletePopup,
  slots: {
    content: '',
  },
});

const activity_codes_columns = computed(() => {
  const activity_codes = active_schedule.value.activity_codes || [];
  const columns = [];
  if (activity_codes.length) {
    columns.push({
      name: 'activity_codes',
      label: $t('Activity Codes'),
      children: [],
    });
  }
  const activity_codes_group = columns.find(column => column.name === 'activity_codes');

  for (const code of activity_codes) {
    activity_codes_group.children.push({
      name: `activity_code_${code.name}`,
      label: code.name,
      min_width: 65,
      width: 65,
      resize: true,
    });
  }

  return columns;
});

const custom_fields_columns = computed(() => {
  const custom_fields = active_schedule.value.custom_fields || {};
  const columns = [];
  if (Object.keys(custom_fields).length) {
    columns.push({
      name: 'custom_fields',
      label: $t('Custom Fields'),
      children: [],
    });
  }
  const custom_fields_group = columns.find(column => column.name === 'custom_fields');
  for (const code of Object.keys(custom_fields)) {
    custom_fields_group.children.push({
      name: `custom_field_${code}`,
      label: code,
      min_width: 65,
      width: 65,
      resize: true,
      custom_type: custom_fields[code].type,
      align: 'center',
    });
  }
  return columns;
});

function saveColumns(close, event) {
  if (event.is_save_clicked) {
    modify_config({
      key: 'columns',
      value: event?.is_new_field_modal_opened ? state.active_columns : event.active_columns,
    });
    set_view_dirtiness(true);
  }
  else {
    state.active_columns = event.active_columns;
  }
  if (close)
    emit('close');
}

function openNewFieldModal() {
  saveColumns(false, {
    is_save_clicked: true,
    is_new_field_modal_opened: true,
  });
  new_field_modal.patchOptions({
    attrs: {
      operation_type: 'create',
      field_name: '',
      field_type: '',
      onSave: () => {
      },
    },
  });
  new_field_modal.open();
}

async function handleDeleteCustomField(custom_field) {
  delete_popup.patchOptions(
    {
      slots: {
        content: `
          <p class="text-gray-600 text-sm font-normal">
            ${$t('Field')} 
            <span class="font-medium text-gray-700">
              ${custom_field.label}
            </span>
            ${$t('will be deleted')}. ${$t('Are you sure you want to delete this')}?
          </p>
        `,
      },
      attrs: {
        header: $t('Delete field'),
        button_text: $t('Delete'),
        options: { teleportTo: is_fullscreen.value ? '#pm-fullscreen-container' : 'body' },
        onClose() {
          delete_popup.close();
        },
        confirm: async () => {
          try {
            await create_update_or_delete_custom_field({
              type: 'delete',
              body: { fields: custom_field.label },
            });
            state.active_columns_saved = state.active_columns_saved.filter(column => column.label !== custom_field.label);
            state.active_columns = state.active_columns.filter(column => column.label !== custom_field.label);
            state.temporary_active_columns = state.active_columns;
            state.all_columns = state.all_columns.map((column) => {
              if (column.name === 'custom_fields')
                column.children = column.children.filter(column => column.label !== custom_field.label);
              return column;
            });
            delete active_schedule.value.custom_fields[custom_field.label];
            delete_popup.close();
          }
          catch (err) {
            logger.error(err);
            $toast({
              title: $t('Something went wrong'),
              text: $t('Please try again'),
              type: 'error',
            });
          }
        },
      },
    },
  );
  delete_popup.open();
}

async function handleUpdateCustomField(custom_field) {
  state.temporary_active_columns = state.active_columns;
  new_field_modal.patchOptions({
    attrs: {
      operation_type: 'update',
      field_name: custom_field.label,
      field_type: custom_field.custom_type,
      onSave: (newVal, oldVal) => {
        state.active_columns = state.temporary_active_columns.map((column) => {
          if (column.name.replace('custom_field_', '') === oldVal) {
            return {
              ...column,
              name: `custom_field_${newVal}`,
              label: newVal,
            };
          }
          return column;
        });
        state.active_columns_saved = state.active_columns_saved.map((column) => {
          if (column.name.replace('custom_field_', '') === oldVal) {
            return {
              ...column,
              name: `custom_field_${newVal}`,
              label: newVal,
            };
          }
          return column;
        });
      },
    },
  });
  new_field_modal.open();
}

function onClose() {
  active_view.value.data.columns = state.active_columns_saved;
  emit('close');
}

function initialize() {
  if (!state.active_columns.length)
    state.is_loading_items = true;
  const columns = getGanttColumns($t, !is_schedule_editable.value, active_schedule.value.track_resources, active_schedule.value.track_costs);

  if (active_schedule.value.has_activity_weightages) {
    const general_group = columns.find(column => column.name === 'general');
    general_group.children.push(...[
      {
        name: 'weight',
        label: $t('Weight'),
        type: 'number',
        align: 'center',
        min_width: 80,
        width: 80,
        resize: true,
      },
    ]);
  }

  if (active_schedule.value.baseline) {
    const dates_group = columns.find(column => column.name === 'dates');
    const durations_group = columns.find(column => column.name === 'durations');
    dates_group.children.push(
      {
        name: 'bl_start',
        label: $t('Baseline Start'),
        group: 'dates',
        group_label: $t('Dates'),
        type: 'date',
        align: 'center',
        min_width: 75,
        resize: true,
      },
      {
        name: 'bl_finish',
        label: $t('Baseline Finish'),
        group: 'dates',
        group_label: $t('Dates'),
        type: 'date',
        align: 'center',
        min_width: 80,
        resize: true,
      },
    );
    durations_group.children.push(
      {
        name: 'bl_duration',
        label: $t('Baseline Duration'),
        group: 'durations',
        group_label: $t('Durations'),
        type: 'number',
        align: 'center',
        min_width: 90,
        resize: true,
      },
    );
  }

  columns.push(...activity_codes_columns.value);
  columns.push(...custom_fields_columns.value);

  state.active_columns = [...active_view.value.data.columns];
  state.active_columns = state.active_columns.map((column) => {
    if (column.name === 'wbs')
      column.lock = true;
    return column;
  });
  if (!state.temporary_active_columns.length)
    state.temporary_active_columns = cloneDeep(state.active_columns);
  if (!state.active_columns_saved.length)
    state.active_columns_saved = cloneDeep(state.active_columns);

  state.all_columns = [...columns];
  state.all_columns = state.all_columns.map((column_group) => {
    column_group.children = column_group.children.map((column) => {
      if (column.name === 'wbs')
        column.lock = true;
      return column;
    });
    return column_group;
  });
  setTimeout(() => {
    state.is_loading_items = false;
  }, 1000);
}

watch(() => active_schedule.value.custom_fields, initialize);

onBeforeMount(initialize);

onKeyStroke('Escape', () => {
  onClose();
});
</script>

<template>
  <HawkFieldsSelector
    :items="state.all_columns"
    :is_loading_items="state.is_loading_items"
    :selected_items="state.active_columns"
    label_key="label"
    value_key="name"
    :min_selected_items="1"
    :texts="{
      heading: $t('Customize columns'),
      left_heading: $t('Available fields'),
      right_heading: $t('Added fields'),
    }"
    :has_field_lock_option="true"
    :close_on_save="false"
    :options="{
      teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body',
      clickToClose: false,
      escToClose: false,
    }"
    :update="(active_columns) => {
      saveColumns(true, { is_save_clicked: true, active_columns });
      emit('close');
    }"
    @click-outside="onClose"
    @active-fields-change="$event => state.active_columns = $event"
    @close="onClose"
  >
    <template #left_item="{ label, is_selected, item }">
      <div
        class="py-2 pl-7 pr-3 text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-50 break-all group"
        :class="{ 'bg-gray-100 hover:!bg-gray-100 selected-available-field': is_selected }"
      >
        <div class="flex justify-between">
          {{ label }}
          <div class="flex gap-2">
            <IconHawkPencilOne
              v-if="item.name.startsWith('custom_field_')"
              class="group-hover:visible invisible text-gray-600"
              @click.stop="handleUpdateCustomField(item)"
            />
            <IconHawkTrashThree
              v-if="item.name.startsWith('custom_field_')"
              class="group-hover:visible invisible text-gray-600"
              @click.stop="handleDeleteCustomField(item)"
            />
          </div>
        </div>
      </div>
    </template>
    <template #right_item="{ label, is_selected, item }">
      <div
        class="py-2 pl-4 pr-3 text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-50 break-all group"
        :class="{ 'bg-gray-100 hover:!bg-gray-100 selected-available-field': is_selected }"
      >
        <div class="flex justify-between">
          {{ label }}
          <div class="flex gap-2">
            <IconHawkLockOne v-if="item.lock" />
            <IconHawkPencilOne
              v-if="item.name.startsWith('custom_field_')"
              class="group-hover:visible invisible text-gray-600"
              @click.stop="handleUpdateCustomField(item)"
            />
            <IconHawkTrashThree
              v-if="item.name.startsWith('custom_field_')"
              class="group-hover:visible invisible text-gray-600"
              @click.stop="handleDeleteCustomField(item)"
            />
          </div>
        </div>
      </div>
    </template>
    <template #additional_content>
      <HawkButton type="text" @click="openNewFieldModal">
        <IconHawkPlus class="text-gray-600" />
        <span class="text-sm font-semibold text-gray-600">
          {{ $t('New Field') }}
        </span>
      </HawkButton>
    </template>
  </HawkFieldsSelector>
</template>
