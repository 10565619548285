<script setup>
const props = defineProps({
  save: {
    type: Function,
    required: true,
  },
});

const emit = defineEmits(['close', 'discard']);

const $t = inject('$t');

const state = reactive({
  is_saving: false,
});

async function onSave() {
  state.is_saving = true;
  await props.save();
  state.is_saving = false;
  emit('close');
}
</script>

<template>
  <HawkModalContainer>
    <div class="col-span-12">
      <HawkModalHeader @close="emit('close')">
        <template #title>
          <div class="font-semibold text-lg">
            Unsaved changes
          </div>
        </template>
      </HawkModalHeader>
      <HawkModalContent>
        <div class="text-gray-700 text-sm">
          Do you want to save your changes before navigating away?
        </div>
      </HawkModalContent>
      <HawkModalFooter>
        <template #right>
          <div class="flex justify-end w-full col-span-full">
            <HawkButton
              name="cancel"
              class="mr-2"
              type="outlined"
              @click="emit('discard')"
            >
              Discard
            </HawkButton>
            <HawkButton
              name="exit"
              :loading="state.is_saving"
              @click="onSave"
            >
              {{ $t('Save') }}
            </HawkButton>
          </div>
        </template>
      </HawkModalFooter>
    </div>
  </HawkModalContainer>
</template>
