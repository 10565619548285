<script setup>
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const $t = inject('$t');

const router = useRouter();

const project_management_store = useProjectManagementStore();
const { is_fullscreen } = storeToRefs(project_management_store);
</script>

<template>
  <HawkModalContainer
    id="pm-resource-modal-container"
    :options="{
      teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body',
      clickToClose: false,
      escToClose: false,
    }"
  >
    <HawkModalHeader :hide_close="true">
      <template #title>
        {{ $t('Please reload the page') }}
      </template>
    </HawkModalHeader>
    <HawkModalContent>
      <div class="text-sm font-medium text-gray-700">
        {{ $t('This schedule has been unpublished by another user. Please reload the page to see the updated schedule.') }}
      </div>
    </HawkModalContent>
    <HawkModalFooter>
      <template #right>
        <div class="flex justify-end">
          <HawkButton
            color="primary"
            @click="router.go()"
          >
            {{ $t('Reload') }}
          </HawkButton>
        </div>
      </template>
    </HawkModalFooter>
  </HawkModalContainer>
</template>
