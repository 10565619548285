<script setup>
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { cloneDeep, isEmpty, isEqual, uniq } from 'lodash-es';
import { useProjectManagementStore } from '~/project-management/store/pm.store';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';

const emit = defineEmits(['close']);
const $t = inject('$t');
const $toast = inject('$toast');
const route = useRoute();
const $services = inject('$services');

const project_management_store = useProjectManagementStore();
const { $g, active_task, active_schedule, active_task_auto_progress_cache, is_fullscreen } = storeToRefs(project_management_store);
const { set_activity_auto_progress, remove_activity_auto_progress_link } = project_management_store;

const state = reactive({
  vueform_columns: {
    default: { container: 12, label: 3, wrapper: 9 },
    sm: { label: 4 },
    md: { label: 4 },
    lg: { label: 4 },
  },
  task_data: {
    container: null,
    blocks: [],
  },
  all_containers: [],
  all_blocks: [],
  all_groups: [],
  all_keys: [],
  all_properties: {},
  filter_properties: { list: [] },
  field_uids: {},
  progress_fields: [],
  is_removing: false,
  is_loading: {
    containers: false,
    blocks: false,
    fields: false,
  },
  is_saving: false,
  filters_set: false,
  is_advanced_options: false,
  reload_count: 0,
  values_map: {},
});

const activity_form_fields = ref(null);
const filter_form$ = ref(null);

const available_keys = computed(() => {
  if (state.filters_set) {
    const keys = state.filter_properties.list.map(prop => prop.key);
    return (
      state.all_keys?.filter(key_obj => !keys.includes(key_obj.name)) ?? []
    );
  }
  return state.all_keys;
});

const has_sync_progress = computed(() => active_task.value?.auto_progress_sync
              && active_task.value?.auto_progress_sync?.is_enabled);

function addFilter() {
  state.filter_properties.list.push({
    key: null,
    values: [],
  });
}

async function getTerraContainers() {
  state.is_loading.containers = true;
  const { data } = await $services.terra_view_service.getAll({
    query: {
      asset: route.params.asset_id,
    },
  });
  state.all_containers = data;
  state.is_loading.containers = false;
}

async function getBlocksInContainer(id) {
  const { data } = await $services.terra_view_service.get({
    id,
  });
  state.all_groups = data?.groups || [];
  state.all_blocks = state.all_groups.map(group => group.projects).flat();
}

async function onContainerSelected(uid) {
  state.task_data.block = [];
  await getBlocksInContainer(uid);
}

async function onBlocksSelected(blocks) {
  try {
    if (
      blocks === undefined
      || !isEqual(active_task_auto_progress_cache.value?.blocks, blocks)
      || blocks.length === 0
    ) {
      state.is_loading.blocks = true;
      state.filter_properties.list = [];
      const { data } = await $services.terra_view_service.post({
        url: 'features/properties',
        body: { projects: blocks },
      });
      state.all_properties = data;
      state.all_keys = Object.keys(data).map(x => ({ name: x }));
    }
  }
  catch (error) {
    logger.error(error);
  }
  finally {
    state.is_loading.blocks = false;
  }
}

function onKeySelected(key, index) {
  if (state.is_loading.container
    || state.is_loading.block
    || state.is_loading.fields
    || !state.filter_properties.list[index]
  )
    return;
  state.filter_properties.list[index].values = [];
  state.values_map[index] = (state.all_properties?.[key] || []).filter(property => property !== null);
}

function removeFilter(index) {
  state.filter_properties.list[index].key = undefined;
}

async function prepopulateFields() {
  if (!active_task.value?.auto_progress_sync?.is_enabled)
    return;

  const container = state.all_containers.find(
    container =>
      container.uid
            === active_task.value.auto_progress_sync.config?.container,
  );

  if (!container)
    return;

  state.is_loading.blocks = true;
  state.task_data.container = container.uid;
  await onContainerSelected(container.uid);

  state.task_data.blocks = state.all_blocks.filter(block =>
    (active_task.value.auto_progress_sync.config?.projects ?? []).includes(
      block.uid,
    ),
  ).map(b => b.uid);
  state.is_loading.blocks = false;

  state.reload_count++;
  state.is_loading.fields = true;
  await onBlocksSelected(state.task_data.blocks);

  const entries = Object.entries(
    active_task.value.auto_progress_sync.config?.filters?.properties || {},
  );

  for (let i = 0; i < entries.length; i++) {
    const [key, values] = entries[i] || [];
    state.filter_properties.list.push({
      key,
      values,
    });
    state.values_map[i] = state.all_properties?.[key];
  }

  state.field_uids = active_task.value.auto_progress_sync.config?.fields ?? {};
  state.is_loading.fields = false;
}

async function getData() {
  if (!isEmpty(active_task_auto_progress_cache.value)) {
    state.all_containers = active_task_auto_progress_cache.value.all_containers;
    state.task_data.container = active_task_auto_progress_cache.value.container;
    state.all_blocks = active_task_auto_progress_cache.value.all_blocks;
    state.all_groups = active_task_auto_progress_cache.value.all_groups;
    state.all_keys = active_task_auto_progress_cache.value.all_keys;
    state.all_properties = active_task_auto_progress_cache.value.all_properties;
    state.task_data.blocks = active_task_auto_progress_cache.value.blocks;
    state.progress_fields = active_task_auto_progress_cache.value.progress_fields;
    state.field_uids = active_task_auto_progress_cache.value.field_uids;
    state.filter_properties.list = active_task_auto_progress_cache.value.filter_properties.list;
    state.values_map = active_task_auto_progress_cache.value.values_map;
  }
  else {
    await getTerraContainers();
    await prepopulateFields();

    project_management_store.auto_update_progress_cache[active_task.value.uid] = {
      ...active_task_auto_progress_cache.value,
      all_containers: state.all_containers,
      container: state.task_data.container,
      all_blocks: state.all_blocks,
      all_groups: state.all_groups,
      all_keys: state.all_keys,
      all_properties: state.all_properties,
      blocks: state.task_data.blocks,
      progress_fields: state.progress_fields,
      field_uids: state.field_uids,
      filter_properties: {
        list: state.filter_properties.list,
      },
      values_map: state.values_map,
    };
  }
}

async function onRemove() {
  try {
    state.is_removing = true;
    await remove_activity_auto_progress_link({
      activity_uid: active_task.value.uid,
    });
    delete project_management_store.auto_update_progress_cache[active_task.value.uid];
  }
  catch (error) {
    logger.error(error);
  }
  finally {
    state.is_removing = false;
    emit('close');
  }
}

async function onSave() {
  if (!state.task_data.blocks.length || state.is_saving)
    return;

  state.is_saving = true;

  const fields_array = state.progress_fields || [];

  if (!fields_array.length) {
    $toast({
      text: $t('No fields selected'),
      type: 'warning', // one of 'success', 'warning', 'error', and 'info' (default 'info')
      timeout: 3000, // timeout in ms (default 2 seconds)
      has_close_button: true,
    });
    state.is_saving = false;
    return;
  }

  const { fields } = fields_array.reduce(
    (acc, curr) => {
      const weight = 1 / fields_array.length;
      acc.fields[curr.name] = weight;
      acc.total_weight += weight;
      return acc;
    },
    { fields: {}, total_weight: 0 },
  );

  try {
    const body = {
      type: 'terra_workflow_progress',
      weighted_average: false,
      container: state.task_data.container,
      projects: state.task_data.blocks,
      filters_properties: state.filter_properties.list.reduce((acc, cur) => {
        if (cur.key)
          acc[cur.key] = cur.values.map(String);
        return acc;
      }, {}),
      fields,
    };

    const { data } = await set_activity_auto_progress(body);
    $g.value.getTask(active_task.value.id).auto_progress_sync = cloneDeep(
      data,
    );

    project_management_store.auto_update_progress_cache[active_task.value.uid] = {
      ...active_task_auto_progress_cache.value,
      all_containers: state.all_containers,
      container: state.task_data.container,
      all_blocks: state.all_blocks,
      all_groups: state.all_groups,
      all_keys: state.all_keys,
      all_properties: state.all_properties,
      blocks: state.task_data.blocks,
      progress_fields: state.progress_fields,
      field_uids: state.field_uids,
      filter_properties: {
        list: state.filter_properties.list,
      },
      values_map: state.values_map,
    };
    activity_form_fields.value.updateFieldsCache();
  }
  catch (error) {
    logger.error(error);
  }
  finally {
    state.is_saving = false;
    emit('close');
  }
}

const is_save_disabled = computed(() => {
  let disable_save = false;
  state.filter_properties.list.forEach((item) => {
    if (item?.key === undefined)
      return;
    if (item?.key === null || !item?.values?.length)
      disable_save = true;
  });
  return disable_save;
});

const is_add_new_disabled = computed(() => {
  let disable_save = false;
  state.filter_properties.list.forEach((item) => {
    if (item?.key === null || (item?.key && !item?.values?.length))
      disable_save = true;
  });
  return disable_save;
});

getData();
</script>

<template>
  <HawkModalTemplate id="pm-update-auto-progress-modal" :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body' }" @close="$emit('close')">
    <template #title_text>
      <div class="text-lg font-semibold text-gray-900">
        {{ $t('Setup automatic progress sync') }}
      </div>
    </template>
    <div
      class="max-h-[calc(100vh-300px)] scrollbar relative w-[550px]"
    >
      <div class="text-sm font-normal text-gray-600 mb-4">
        {{ $t('pm-auto-update-progress-sync-text-1') }}
        <!-- TODO: Action on this learn more -->
        <!-- <span class="font-semibold text-gray-800">
          {{ $t('Learn more') }}
        </span> -->
        <br>
        <br>
        <span class="font-semibold text-gray-800">
          {{ $t('Note') }}:
        </span>
        {{ $t('pm-auto-update-progress-sync-text-2') }}
      </div>
      <Vueform
        v-model="state.task_data"
        size="sm"
        :columns="state.vueform_columns"
        sync
        class="mb-6"
        :display-errors="false"
      >
        <SelectElement
          name="container"
          label-prop="name"
          value-prop="uid"
          :label="$t('Map')"
          :placeholder="$t('Choose a map')"
          :description="$t('Select a map to consider the progress from')"
          :items="state.all_containers"
          :search="true"
          :native="false"
          :can-clear="false"
          :can-deselect="false"
          input-type="search"
          autocomplete="off"
          append-to="#pm-update-auto-progress-modal"
          class="mb-4"
          :rules="['required']"
          :messages="{ required: 'This field is required.' }"
          :loading="state.is_loading.containers"
          @change="onContainerSelected"
        />
        <HawkTreeSelect
          :key="state.all_groups.length + state.reload_count"
          :options="{
            name: 'blocks',
            placeholder: $t('Choose one/more layers'),
            description: $t('Select atleast one layer to lookup construction monitoring updates from'),
            appendTo: '#pm-update-auto-progress-modal',
            loading: state.is_loading.blocks,
          }"
          :label="$t('Blocks')"
          :data="state.all_groups"
          children_key="projects"
          label_key="name"
          value_key="uid"
          :initial_state="state.task_data.blocks"
          select_type="LEAF_PRIORITY"
          :rules="['required']"
          :messages="{ required: 'This field is required.' }"
          @updateForm="state.task_data.blocks = $event; onBlocksSelected($event)"
        />
      </Vueform>
      <PmActivityFormFields
        ref="activity_form_fields"
        :field_uids="state.field_uids"
        :is_loading="state.is_loading.fields"
        @update="state.progress_fields = $event"
      />
      <div class="w-40 mt-8 cursor-pointer" @click="state.is_advanced_options = !state.is_advanced_options">
        <div class="flex items-center text-sm font-semibold text-primary-700">
          <IconHawkChevronRight v-if="!state.is_advanced_options" class="w-6 h-6" />
          <IconHawkChevronDown v-else class="w-6 h-6" />
          {{ $t('Advanced options') }}
        </div>
      </div>
      <div v-show="state.is_advanced_options" class="mt-8 overflow-x-hidden">
        <div class="mb-5 font-semibold text-gray-700 text-md">
          {{ $t('Filters') }}
        </div>
        <template v-for="(property, idx) in state.filter_properties" :key="idx">
          <Vueform
            ref="filter_form$"
            v-model="state.filter_properties"
            size="sm"
            :sync="true"
            :columns="state.vueform_columns"
          >
            <ListElement
              name="list"
              :add-classes="{ ListElement: { list: ['!gap-0'] } }"
              :controls="{ add: false, remove: false, sort: false }"
            >
              <template #default="{ index }">
                <ObjectElement
                  v-if="state.filter_properties.list?.[index]?.key !== undefined"
                  :name="index"
                  class="mb-3"
                >
                  <!-- in that ^ v-if, the value can be undefined only when a list item is "deleted"; check the removeFilter fn -->
                  <div class="flex w-full col-span-full">
                    <SelectElement
                      name="key"
                      :items="available_keys"
                      label-prop="name"
                      value-prop="name"
                      :search="true"
                      :native="false"
                      :default="[state.filter_properties.list[index].key]"
                      :can-clear="false"
                      input-type="search"
                      autocomplete="off"
                      append-to="#pm-update-auto-progress-modal"
                      class="w-60 mr-4"
                      @change="onKeySelected($event, index)"
                      @updated="state.filters_set = true"
                    />
                    <VTagsInput
                      :options="{
                        'name': 'values',
                        'items': uniq([
                          ...(state.filter_properties.list?.[index]?.values || []),
                          ...(state.values_map?.[index]?.filter?.(property => property !== null) || []),
                        ]),
                        'search': true,
                        'native': false,
                        'default': state.filter_properties.list[index].values,
                        'can-clear': false,
                        'create': true,
                        'tags_removable': true,
                        'input-type': 'search',
                        'autocomplete': 'off',
                        'class': 'w-[260px] mr-4',
                        'label-prop': 'label',
                        'append-to': '#pm-update-auto-progress-modal',
                      }"
                    />
                    <HawkButton
                      type="text"
                      :icon="true"
                      class="ml-auto min-w-[40px]"
                      @click="removeFilter(index)"
                    >
                      <IconHawkTrashThree class="text-gray-500" />
                    </HawkButton>
                  </div>
                </ObjectElement>
              </template>
            </ListElement>
          </vueform>
        </template>
        <HawkButton
          type="text"
          :disabled="is_add_new_disabled"
          :class="{ 'cursor-not-allowed': is_add_new_disabled }"
          @click="addFilter"
        >
          <IconHawkPlus :class="is_add_new_disabled ? 'text-gray-400' : 'text-gray-600'" />
          <span :class="is_add_new_disabled ? 'text-gray-400' : 'text-gray-600'">
            {{ $t('Add filter') }}
          </span>
        </HawkButton>
      </div>
    </div>
    <template #footer>
      <Vueform size="sm">
        <div class="flex justify-between w-full col-span-full">
          <HawkButton v-if="has_sync_progress" type="plain" color="error" :loading="state.is_removing" @click="onRemove()">
            {{ $t('Remove') }}
          </HawkButton>
          <div class="flex justify-end w-full col-span-full">
            <ButtonElement name="cancel" class="mr-4" :secondary="true" @click="$emit('close')">
              {{ $t('Cancel') }}
            </ButtonElement>
            <ButtonElement
              name="save"
              :loading="state.is_saving"
              :disabled="is_save_disabled"
              @click="onSave()"
            >
              {{ $t('Save') }}
            </ButtonElement>
          </div>
        </div>
      </Vueform>
    </template>
  </HawkModalTemplate>
</template>
