<script setup>
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const props = defineProps({
  mode: {
    type: String,
    default: 'create',
  },
  existing_items: {
    type: Array,
    default: () => [],
  },
  prefill_data: {
    type: Object,
    default: () => ({}),
  },
  save: {
    type: Function,
    required: false,
  },
});

const emit = defineEmits(['close', 'save', 'addAnother']);

const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const { active_schedule, is_fullscreen } = storeToRefs(project_management_store);

const cost_type_items = [
  {
    value: 'per_item',
    label: $t('Per item'),
  },
  {
    value: 'per_hour',
    label: $t('Per hour'),
  },
  {
    value: 'fixed',
    label: $t('Fixed'),
  },
];

const form$ = ref(null);

const state = reactive({
  form_data: props.prefill_data,
  add_another: false,
  is_loading: false,
  assignee_key: 0,
});

async function onSave() {
  if (props.mode === 'edit') {
    state.is_loading = true;
    emit('save', state.form_data);
  }
  else {
    await props.save(state.form_data);
    if (state.add_another) {
      state.add_another = false;
      form$.value.reset();
      state.assignee_key++;
      emit('addAnother');
    }
    else {
      emit('close');
    }
  }
}
</script>

<template>
  <HawkModalContainer
    id="pm-resource-modal-container"
    :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body' }"
  >
    <Vueform
      ref="form$"
      v-model="state.form_data"
      sync
      size="sm"
      :display-errors="false"
      :display-messages="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="flex flex-col justify-start">
              <template v-if="props.mode === 'create'">
                {{ $t('New resource') }}
              </template>
              <template v-else-if="props.mode === 'edit'">
                {{ $t('Edit resource') }}
              </template>
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <HawkAssigneeInput
            :key="state.assignee_key"
            :options="{
              name: 'resource_name',
              label: $t('Resource name'),
              canClear: false,
              rules: 'required',
              placeholder: $t('Select members'),
              existing_users: props.existing_items,
              existing_teams: props.existing_items,
              size: 'sm',
              has_teams: true,
              create: true,
              appendNewOption: true,
              disabled: props.mode === 'edit',
              appendTo: '#pm-resource-modal-container',
            }"
            class="mb-6"
          >
            <template #option="{ option, uid }">
              <div v-if="!state.search" />
              <div class="absolute left-0 flex items-center h-full pl-3">
                <div
                  v-if="option.__CREATE__"
                  class="flex gap-1 text-sm font-semibold text-gray-700 break-words rounded-lg cursor-pointer hover:bg-gray-50"
                >
                  <IconHawkPlus />{{ $t('Add') }} "{{ option.name }}"
                </div>
                <div
                  v-else-if="option.name === option.uid"
                  class="flex gap-1 text-sm font-semibold text-gray-700 break-words rounded-lg cursor-pointer hover:bg-gray-50"
                >
                  {{ option.name }}
                </div>
                <HawkMembers
                  v-else
                  :members="uid"
                  size="xs"
                  type="label"
                />
              </div>
            </template>
            <template #single-label="{ value, uid }">
              <div v-if="value.name === value.uid" class="absolute left-0 flex items-center h-full pl-3">
                <div
                  class="flex gap-1 text-sm font-semibold text-gray-700 break-words rounded-lg"
                >
                  {{ value.name }}
                </div>
              </div>
              <div v-else class="absolute left-0 flex items-center h-full pl-3">
                <HawkMembers
                  :members="uid"
                  size="xs"
                  type="label"
                />
              </div>
            </template>
          </HawkAssigneeInput>
          <!-- <SelectElement
            v-if="state.form_data?.resource_name?.name === state.form_data?.resource_name?.uid"
            name="access_level"
            :label="$t('Access level')"
            rules="required"
          /> -->
          <template v-if="active_schedule.track_costs">
            <SelectElement
              name="cost_type"
              :class="state.form_data?.cost_type !== 'fixed' ? 'my-6' : 'mt-6'"
              :can-clear="false"
              :can-deselect="false"
              :label="$t('Cost type')"
              :native="false"
              :items="cost_type_items"
              rules="required"
              append-to="#pm-resource-modal-container"
            />
            <TextElement
              v-if="state.form_data?.cost_type !== 'fixed'"
              name="cost"
              :label="$t('Cost')"
              input-type="number"
              :rules="['required', 'numeric', 'min:0']"
            >
              <template #addon-before>
                {{ active_schedule?.currency?.symbol }}
              </template>
            </TextElement>
          </template>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <HawkButton
                type="text"
                class="mr-4"
                :secondary="true"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement
                v-if="props.mode === 'create'"
                name="save_and_add"
                secondary
                class="mr-4"
                submits
                @click="state.add_another = true"
              >
                {{ $t('Save and add another') }}
              </ButtonElement>
              <ButtonElement
                name="save"
                :loading="state.is_loading"
                submits
              >
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
