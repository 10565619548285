<!-- TODO: this file and the field mappings functionality in general needs to be checked if it works with start_date, end_date, and text -->
<script setup>
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const emit = defineEmits(['close']);

const $t = inject('$t');
const $toast = inject('$toast');
const $services = inject('$services');

const route = useRoute();
const common_store = useCommonStore();
const project_management_store = useProjectManagementStore();
const { assets_custom_fields_map, assets_custom_fields } = storeToRefs(common_store);
const { active_task, active_schedule, is_fullscreen } = storeToRefs(project_management_store);

const state = reactive({
  form_data: {
    list: [],
  },
  selected_custom_fields: [],
  selected_custom_fields_of_other_tasks_or_schedules: [],
  initial_data: [],
  is_loading: false,
  is_saving: false,
});

const field_type_mapping = {
  Date: ['datetime', 'date_range'],
  Duration: ['number', 'planned_actual'],
  Percentage: ['number', 'percentage', 'planned_actual'],
};

const all_properties = computed(() => {
  return [
    ['bl_start', 'Baseline start', 'Date'],
    ['bl_finish', 'Baseline finish', 'Date'],
    ['planned_start', 'Planned start', 'Date'],
    ['planned_finish', 'Planned finish', 'Date'],
    ['actual_start', 'Actual start', 'Date'],
    ['actual_finish', 'Actual finish', 'Date'],
    ['start', 'Start', 'Date'],
    ['finish', 'Finish', 'Date'],
    ['bl_duration', 'Baseline duration', 'Duration'],
    ['planned_duration', 'Planned duration', 'Duration'],
    ['actual_duration', 'Actual duration', 'Duration'],
    ['duration', 'Duration', 'Duration'],
    ['remaining_duration', 'Remaining duration', 'Duration'],
    ['percent_schedule_complete', 'Scheduled progress', 'Percentage'],
    ['progress', 'Progress', 'Percentage'],
  ].map((item) => {
    return {
      value: item[0],
      label: $t(item[1]),
      type: item[2],
    };
  });
});

const all_custom_fields = computed(() => {
  const field_mappings = active_schedule.value.asset_mapping?.configs?.map?.((config) => {
    const base_uid = config.field_uid.replace(/__(planned|actual|start|end)$/, '');
    const item = assets_custom_fields.value.find(item => item.uid === base_uid);

    if (item) {
      let label = item.name;
      if (config.field_uid.includes('__planned'))
        label += ` [${$t('Planned')}]`;
      else if (config.field_uid.includes('__actual'))
        label += ` [${$t('Actual')}]`;
      else if (config.field_uid.includes('__start'))
        label += ` [${$t('Start')}]`;
      else if (config.field_uid.includes('__end'))
        label += ` [${$t('End')}]`;

      return {
        ...item,
        uid: config.field_uid,
        label,
        value: label,
      };
    }
    else {
      return false;
    }
  }).filter(Boolean);

  const custom_fields_not_in_configs = assets_custom_fields.value.filter((item) => {
    const related_mappings = field_mappings?.filter?.(mapping => mapping.uid.startsWith(item.uid));
    if (item.type === 'planned_actual' || item.type === 'date_range')
      return related_mappings?.length < 2 || related_mappings === undefined;
    else
      return related_mappings?.length === 0 || related_mappings === undefined;
  });

  const custom_fields_with_suffixes = custom_fields_not_in_configs.flatMap((item) => {
    if (item.type === 'planned_actual') {
      const planned_item = {
        ...item,
        uid: `${item.uid}__planned`,
        label: `${item.name} [${$t('Planned')}]`,
        value: `${item.name} [${$t('Planned')}]`,
      };

      const actual_item = {
        ...item,
        uid: `${item.uid}__actual`,
        label: `${item.name} [${$t('Actual')}]`,
        value: `${item.name} [${$t('Actual')}]`,
      };

      return [planned_item, actual_item];
    }

    if (item.type === 'date_range') {
      const start_item = {
        ...item,
        uid: `${item.uid}__start`,
        label: `${item.name} [${$t('Start')}]`,
      };

      const end_item = {
        ...item,
        uid: `${item.uid}__end`,
        label: `${item.name} [${$t('End')}]`,
      };

      return [start_item, end_item];
    }

    return {
      label: item.name,
      value: item.name,
      ...item,
    };
  });

  return [
    ...(Array.isArray(field_mappings) ? field_mappings : []),
    ...custom_fields_with_suffixes,
  ];
});

const items = computed(() => {
  return all_custom_fields.value.map((item, index) => {
    const type_of_property = all_properties.value.find(property => property.value === state.form_data.list?.[index]?.properties)?.type;
    return all_custom_fields.value.filter(
      option =>
        !state.selected_custom_fields.includes(option.uid)
        || option.uid === state.selected_custom_fields[index],
    ).filter(
      option => field_type_mapping[type_of_property]?.includes?.(option.type),
    ).filter(
      option => !state.selected_custom_fields_of_other_tasks_or_schedules.includes(option.uid),
    );
  });
});

const add_update_and_delete = computed(() => {
  const original_list = state.initial_data;
  const new_list = state.form_data.list;

  const add = [];
  const update = [];
  const deletes = [];

  new_list?.forEach?.((new_item) => {
    const original_item = original_list.find(item => item.field_uid === new_item.field_uid);
    if (!original_item)
      add.push(new_item);
    else if (original_item.field_uid !== new_item.field_uid
      || original_item.activity_uid !== new_item.activity_uid
      || original_item.properties !== new_item.properties
    )
      update.push(new_item);
  });

  original_list.forEach((original_item) => {
    const new_item = new_list.find(item => item.field_uid === original_item.field_uid);
    if (!new_item)
      deletes.push(original_item);
  });

  const payload = {
    add,
    update,
    delete: deletes.map(item => item.field_uid),
  };

  return payload;
});

const is_save_disabled = computed(() => {
  return (
    add_update_and_delete.value.add.length === 0
    || add_update_and_delete.value.add.some(item => !item.field_uid)
    || add_update_and_delete.value.add.some(item => !item.properties)
  ) && (
    add_update_and_delete.value.update.length === 0
    || add_update_and_delete.value.update.some(item => !item.field_uid)
    || add_update_and_delete.value.update.some(item => !item.properties)
  )
    && add_update_and_delete.value.delete.length === 0;
});

function addFieldMapping() {
  state.form_data.list.push({
    properties: null,
    field_uid: '',
    activity_uid: active_task.value.uid,
  });
}

function removeFilter(index) {
  state.form_data.list.splice(index, 1);
}

async function onSave() {
  state.is_saving = true;

  const payload = add_update_and_delete.value;
  try {
    const response = await $services.project_management.post({
      url: 'project-management/schedules',
      id: active_schedule.value.uid,
      attribute: 'asset-mapping',
      body: payload,
    });
    active_schedule.value.asset_mapping = response.data.data.asset_mapping;
    for (const asset_map_item of response.data.data.asset_mapping.configs)
      assets_custom_fields_map.value[asset_map_item.field_uid] = {
        ...assets_custom_fields_map.value?.[asset_map_item.field_uid],
        uid: asset_map_item.field_uid,
        properties: {
          ...assets_custom_fields_map.value[asset_map_item.field_uid]?.properties,
          key: asset_map_item?.properties,
          activity_uid: asset_map_item?.activity_uid,
          schedule_uid: active_schedule?.value.uid,
        },
      };

    for (const deleted_item of add_update_and_delete.value.delete)
      if (assets_custom_fields_map.value[deleted_item]) {
        delete assets_custom_fields_map.value[deleted_item].properties.schedule_uid;
        delete assets_custom_fields_map.value[deleted_item].properties.activity_uid;
      }
  }
  catch (error) {
    $toast({
      title: error?.data?.title || $t('Something went wrong'),
      text: error?.data?.description || $t('Please try again'),
      type: 'error',
    });
  }
  finally {
    state.is_saving = false;
    emit('close');
  }
}

function updateSelectedValue(index, value) {
  state.selected_custom_fields[index] = value;
}

function resetFieldUid(index) {
  state.form_data.list[index].field_uid = '';
}

onMounted(async () => {
  state.is_loading = true;
  const response = await $services.project_management.get({
    url: 'project-management/schedules',
    attribute: `asset-mapping/${route.params.asset_id}`,
  });
  state.initial_data = assets_custom_fields.value
    ?.map(item => item.uid);
  state.initial_data = active_schedule.value.asset_mapping?.configs?.filter?.((item) => {
    return state.initial_data.some(data => item.field_uid.includes(data));
  })?.filter?.(item => item.activity_uid === active_task.value?.uid) || [];
  state.form_data.list = [...state.initial_data];

  state.selected_custom_fields = state.form_data.list.map(item => item.field_uid);

  response.data.data.configs.forEach((config) => {
    const has_different_schedule_uid = !!config?.schedule_uid && config?.schedule_uid !== active_schedule.value?.uid;
    const has_different_activity_uid = !!config?.activity_uid && config?.activity_uid !== active_task.value?.uid;
    if (has_different_schedule_uid || has_different_activity_uid)
      state.selected_custom_fields_of_other_tasks_or_schedules.push(config.field_uid);
  });
  state.is_loading = false;
});
</script>

<template>
  <HawkModalContainer
    :options="{
      teleportTo: is_fullscreen
        ? '#pm-fullscreen-container'
        : 'body',
    }"
    id="pm-field-mappings-modal-container"
  >
    <Vueform
      v-model="state.form_data"
      size="sm"
      sync
      :display-errors="false"
      :display-messages="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="text-lg font-semibold text-gray-900">
              {{ $t('Sync properties') }}
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <HawkLoader v-if="state.is_loading" />
          <template v-else>
            <div class="text-sm font-normal text-gray-600 mb-4">
              {{ $t('pm-sync-properties-text-1') }}
              <br>
              <br>
              <span class="font-semibold text-gray-800">
                {{ $t('Note') }}:
              </span>
              {{ $t('pm-sync-properties-text-2') }}
            </div>
            <div
              v-if="state.form_data.list?.length"
              class="flex gap-[52px] text-sm font-medium text-gray-700 mb-1"
            >
              <div class="w-60">
                {{ $t('Schedule property') }}
              </div>
              <div class="w-56">
                {{ $t('Asset property') }}
              </div>
            </div>
            <ListElement
              name="list"
              :add-classes="{ ListElement: { list: ['!gap-0'] } }"
              :controls="{ add: false, remove: false, sort: false }"
            >
              <template #default="{ index }">
                <ObjectElement
                  v-if="state.form_data.list?.[index]?.properties !== undefined"
                  :name="index"
                  class="mb-3"
                >
                  <div class="flex w-full col-span-12">
                    <SelectElement
                      name="properties"
                      :items="all_properties"
                      :search="true"
                      :native="false"
                      :can-clear="false"
                      input-type="search"
                      autocomplete="off"
                      append-to='#pm-field-mappings-modal-container'
                      class="w-60"
                      @select="resetFieldUid(index)"
                    />
                    <div class="flex items-center h-full mx-4">
                      <IconHawkArrowRight class="text-gray-600" />
                    </div>
                    <SelectElement
                      name="field_uid"
                      :items="items[index]"
                      value-prop="uid"
                      :search="true"
                      :native="false"
                      :can-clear="false"
                      input-type="search"
                      autocomplete="off"
                      append-to='#pm-field-mappings-modal-container'
                      class="w-56"
                      @change="updateSelectedValue(index, $event)"
                    />
                    <div
                      class="flex items-center h-full ml-4 cursor-pointer"
                      @click="removeFilter(index)"
                    >
                      <IconHawkTrashThree class="text-gray-600" />
                    </div>
                  </div>
                </ObjectElement>
              </template>
            </ListElement>
            <HawkButton
              type="text"
              @click="addFieldMapping"
            >
              <IconHawkPlus class="text-gray-600" />
              <span class="text-gray-600">
                {{ $t('New property') }}
              </span>
            </HawkButton>
          </template>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <ButtonElement
                name="cancel"
                class="mr-4"
                :secondary="true"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </ButtonElement>
              <ButtonElement
                name="save"
                :disabled="is_save_disabled"
                :loading="state.is_saving"
                @click="onSave"
              >
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
