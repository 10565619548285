<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useChatStore } from '~/common/stores/chat.store.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

defineOptions({
  name: 'PmComment',
  inheritAttrs: false,
  customOptions: {},
});

const props = defineProps({
  comment: {
    type: Object,
    required: true,
  },
  disabled_controls: {
    type: Boolean,
    default: false,
  },
  is_reply: {
    type: Boolean,
    default: false,
  },
  activity_uid: {
    type: String,
    required: true,
  },
  asset_id: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['replies-loaded']);

const location = inject('location');

const chat_store = useChatStore();
const { logged_in_user_details } = useAuthStore();
const project_management_store = useProjectManagementStore();

const { active_task, is_schedule_editable } = storeToRefs(project_management_store);
const { update_activity_backend, update_activity, set_pm_comments_and_attachments } = project_management_store;

const logged_in_user_id = logged_in_user_details?.user_id;
const can_reply = ref(false);
const replies = ref(null);
const show_replies = ref(true);

const comment_attachments = computed(() => {
  const attachments = [...(active_task.value?.attachments || [])];
  const attachment_uids = props.comment?.attachment_uids || [];
  return attachments.filter(a => attachment_uids.includes(a.uid));
});

const liked_by_me = computed(() => {
  return props.comment?.own_reactions?.[0]?.type === 'like';
});

const disabled_controls = computed(() => {
  let disabled_control_list = ['settings'];
  if (props.disabled_controls)
    return props.disabled_controls;
  if (props.is_reply)
    disabled_control_list = disabled_control_list.concat(['pin', 'reply']);
  if (logged_in_user_id !== props.comment?.user?.id)
    disabled_control_list.push('edit');
  return disabled_control_list;
});

watch(() => props.comment, async (new_comment) => {
  if (new_comment.reply_count)
    await getReplies();
  // eslint-disable-next-line vue/custom-event-name-casing
  emit('replies-loaded');
}, { immediate: true });

async function getReplies() {
  if (props.comment?.id && props.comment?.reply_count) {
    const messages = await chat_store.chat_channel.getReplies(props.comment.id);
    replies.value = messages.messages || [];
  }
}

function toggleReplyInput() {
  can_reply.value = true;
  show_replies.value = true;
  const scroll = () => {
    const block = document.getElementById(`${location}-block`);
    const targetDiv = document.getElementById(`${props.comment.id}-input`);
    const targetRect = targetDiv.getBoundingClientRect();
    const location_offset = location === 'conversations' ? 180 : 195;
    const scrollPosition = block.scrollTop + targetRect.bottom - window.innerHeight + location_offset;
    block.scrollTo({ top: scrollPosition, behavior: 'smooth' });
  };
  setTimeout(() => scroll(), 100);
}

async function deleteComment() {
  await chat_store.chat.client.deleteMessage(props.comment.id);
}

async function deleteAttachment(file) {
  if (props.comment.text) {
    const attachment_uids = props.comment.attachment_uids.filter(uid => uid !== file.uid);

    const payload = {
      ...props.comment,
      attachment_uids,
    };

    await chat_store.updateMessage(payload);
  }
  else {
    await deleteComment();
  }
  update_activity({
    uid: active_task.value.uid,
    attachments: active_task.value.attachments.filter(a => a.uid !== file.uid),
  }, false, ['attachments']);
  const body = { attachments: { delete: [file.uid] } };
  const data = await update_activity_backend(active_task.value?.uid, body);
  data.data.forEach((item) => {
    update_activity(item, true);
  });
  await set_pm_comments_and_attachments(false, true);
}

function closeReply() {
  can_reply.value = false;
}

defineExpose({ closeReply });
</script>

<template>
  <div :id="comment.id">
    <div
      class="flex items-start hover:bg-gray-50 rounded group px-3 py-2 -ml-3 -mr-3"
      :class="{
        'opacity-50': props.comment.type === 'deleted',
      }"
    >
      <HawkMembers size="xs" :members="props.comment?.user?.id" class="mr-2" />
      <div class="w-full">
        <div
          class="flex items-center mb-0.5 flex-wrap"
          :class="{ 'w-full justify-between': !comment_attachments?.length }"
        >
          <div class="mr-2 text-center flex items-center">
            <HawkMembers
              :members="props.comment.user.id"
              :has_avatar="false"
              type="label"
              class="text-sm whitespace-nowrap"
            />
            <IconHawkPinned v-if="props.comment.pinned" class="h-3.5 w-3.5 mx-2 text-warning-400" />
          </div>
          <p
            v-if="props.comment.created_at"
            class="text-xs font-regular text-gray-600 text-center whitespace-nowrap"
            :class="{
              'group-hover:hidden': props.comment.type !== 'deleted',
            }"
          >
            {{ $date(props.comment.created_at, 'DATETIME_LONG_WITH_LONG_DAY_AND_MONTH') }}
          </p>
        </div>
        <p
          v-if="props.comment.type === 'deleted'"
          class="text-sm font-regular text-gray-700 italic"
        >
          {{ is_reply ? $t('This reply was deleted') : $t('This message was deleted') }}
        </p>
        <div v-else class="-mx-2">
          <PmCommentInput
            v-if="props.comment.text"
            :key="props.comment.text"
            :activity_uid="activity_uid"
            :chat_store="chat_store"
            :user="logged_in_user_id"
            :comment="comment"
            :comment_input_config="{
              disabled_controls,
              liked_by_me,
              type: 'static',
              comment_text: props.comment.text,
              reaction_count: props.comment?.reaction_counts?.like,
            }"
            @reply="toggleReplyInput"
            @delete="deleteComment"
          >
            <template #right_footer>
              <div
                v-if="replies && replies.length"
                class="text-sm font-semibold text-blue-700 cursor-pointer"
                @click="show_replies = !show_replies"
              >
                {{ show_replies ? $t('Hide replies') : $t('Show replies') }} ({{ replies?.length || 0 }})
              </div>
            </template>
          </PmCommentInput>
          <div v-if="comment_attachments?.length" class="w-fit mb-4 mt-2">
            <HawkAttachmentsGrid
              :items="comment_attachments"
              :can_delete="logged_in_user_id === comment.user.id"
              @delete="deleteAttachment"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="show_replies && replies?.length" class="ml-11">
      <div v-for="(reply, index) in replies" :key="index">
        <PmComment
          v-bind="is_schedule_editable
            ? { disabled_controls: ['settings', 'reply', 'pin', 'edit', 'delete', 'like'] }
            : {}"
          :asset_id="props.asset_id"
          :comment="reply"
          :is_reply="true"
        />
      </div>
    </div>
    <div v-if="can_reply" :id="`${comment.id}-input`" class="mt-2 mb-4 flex ml-11">
      <HawkMembers size="xs" :members="logged_in_user_id" class="mr-3 h-8" />
      <div class="w-full">
        <PmCommentInput
          type="reply"
          :activity_uid="activity_uid"
          :chat_store="chat_store"
          :user="logged_in_user_id"
          :comment="comment"
          :comment_input_config="{
            autofocus: true,
            disabled_controls: ['settings'],
            type: 'static',
          }"
          @close="can_reply = false"
        />
      </div>
    </div>
  </div>
</template>
