import { cloneDeep } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { reactive, ref } from 'vue';
import { useModal } from 'vue-final-modal';
import { createUppyInstance } from '~/common/utils/uppy.util.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store';
import AttachmentUpload from '~/tasks/components/organisms/attachment-upload.vue';

export function useAttachments() {
  const project_management_store = useProjectManagementStore();

  const {
    update_activity,
    update_activity_backend,
    set_pm_comments_and_attachments,
  } = project_management_store;
  const { active_task } = storeToRefs(project_management_store);

  const after_submit_fn = ref(null);
  const files_to_attach = ref([]);

  const state = reactive({
    are_files_added: false,
    uppy: createUppyInstance({
      attachment_config: { meta: { service: 'pm', uid: active_task.value?.uid } },
      complete_cb: onFilesAdded,
    }),
  });

  const attachment_upload_modal = useModal({
    component: AttachmentUpload,
    attrs: {
      task_uid: active_task.value?.uid,
      attachment_config: { meta: { service: 'pm', uid: active_task.value?.uid } },
      fields_list: [{ name: 'File', component: 'MultifileElement', auto: false }],
      submit: async (form) => {
        state.are_files_added = false;
        const files = cloneDeep(form.data?.File);
        files?.length && state.uppy.addFiles(files);
        // return a new promise that waits until the upload is complete
        await new Promise((resolve) => {
          setInterval(() => {
            if (state.are_files_added)
              resolve();
          }, 100);
        });
        const attachment_uids = await addAttachmentsToActivity();
        after_submit_fn.value?.(attachment_uids);
      },
      onClose: () => {
        attachment_upload_modal.close();
      },
    },
  });

  async function addAttachmentsToActivity(files_payload = null) {
    const files = cloneDeep(files_payload || files_to_attach.value);
    files_to_attach.value = [];

    if (!files.length)
      return;

    const body = { attachments: { add: [] } };

    files.forEach((file) => {
      body.attachments.add.push({
        ...file,
        url: file.upload_url || file.service.location,
        mime_type: file.mime_type || file.file_type,
        fileName: file.fname || file.file_name,
        fileSize: file.file_size,
        service: file.service_object || file.service,
      });
    });
    let attachment_uids = [];

    try {
      const { data } = await update_activity_backend(active_task.value?.uid, body);

      data.forEach((item) => {
        if (item.uid === active_task.value?.uid)
          attachment_uids = item.attachments.map(item => item.uid);
        update_activity(item, true);
      });

      set_pm_comments_and_attachments(false, true);

      return attachment_uids;
    }
    catch (error) {
      logger.error(error);
    }

    return [];
  }

  async function onFilesAdded(result) {
    const successful_uuids = result.successful.map(thing => thing.data.uuid);
    const failed_uuids = result.failed.map(thing => thing.data.uuid);
    successful_uuids.forEach(onRemoveAttachment);
    failed_uuids.forEach(onRemoveAttachment);
    const files = result.successful.map((res) => {
      res.data.upload_pending = false;
      return res.data;
    });
    addFilesToAttach(files);
    state.are_files_added = true;
    attachment_upload_modal.close();
  }

  function addFilesToAttach(files) {
    files_to_attach.value.push(...files);
  }

  function onRemoveAttachment(uuid) {
    files_to_attach.value = files_to_attach.value.filter(att => att.uuid !== uuid);
  }

  return {
    after_submit_fn,
    files_to_attach,
    attachment_upload_modal,
    addAttachmentsToActivity,
  };
}
