import dayjs from 'dayjs';
import { intersection, isNil } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';
import { useAuthStore } from '~/auth/stores/auth.store.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

export function useFilters() {
  const auth_store = useAuthStore();
  const project_management_store = useProjectManagementStore();

  const { $g, active_view, active_schedule, filtered_task_ids } = storeToRefs(project_management_store);

  const active_date_range = computed(() => active_view.value.data.active_date_range);
  const wbs_level = computed(() => active_view.value.data.wbs_level);
  const filters = computed(() => active_view.value.data.filters);

  watch(wbs_level, () => {
    $g.value.render();
  });

  watch(filters, () => {
    $g.value.render();
  }, { deep: true });

  function createFilterMaps(filters_array) {
    const activity_code_filters = Object.keys(filters_array).filter(
      name =>
        name.startsWith('__activity_code_') && Object.values(filters_array[name]).length,
    );
    const activity_code_map = activity_code_filters.reduce((acc, cur) => {
      acc[cur] = cur.replace('__activity_code_', '');
      return acc;
    }, {});

    return { activity_code_filters, activity_code_map };
  }

  function checkActivityAndMilestone(filters_array, task) {
    return (
      (
        filters_array.activity
        && task.type !== $g.value.config.types.task
      )
      || (
        filters_array.milestone
        && task.type !== $g.value.config.types.milestone
      )
    );
  }

  function checkCriticalAndNotCritical(filters_array, task) {
    return (
      (
        filters_array.critical
        && (
          task.progress === 1
          || !$g.value.isCriticalTask(task)
          || !(active_schedule.value.deadline ? dayjs(task.end_date).isAfter(active_schedule.value.deadline) : true)
        )
      )
      || (
        filters_array.not_critical
        && (
          task.progress !== 1
          && (
            $g.value.isCriticalTask(task)
            || (active_schedule.value.deadline ? dayjs(task.end_date).isAfter(active_schedule.value.deadline) : false)
          )
        )
      )
    );
  }

  function checkProgressUpdate(filters_array, task) {
    return (
      (
        filters_array.progress_updates === 'automatic'
        && !task.auto_progress_sync.is_enabled
      )
      || (
        filters_array.progress_updates === 'manual'
        && task.auto_progress_sync.is_enabled
      )
    );
  }

  function checkTaskLevel(task_level) {
    return task_level > wbs_level.value;
  }

  function checkStatuses(filters_array, task) {
    if (filters_array.statuses?.length) {
      let allow = false;

      // Rule #1: If task has not started
      if (
        filters_array.statuses.includes('not-started')
        && task.progress === 0
      ) {
        allow = true;
      }

      // Rule #2: If task has started
      if (
        filters_array.statuses.includes('started')
        && task.progress !== 0
        && task.progress !== 1
      ) {
        allow = true;
      }

      // Rule #3: If task has finished
      if (filters_array.statuses.includes('finished') && task.progress === 1)
        allow = true;

      return !allow;
    }
    return false;
  }

  function checkOverdue(filters_array, task) {
    return filters_array.overdue && (task.progress >= task.percent_schedule_complete);
  }

  function checkAssignedToMe(filters_array, task) {
    return filters_array.assigned_to_me && !task.assignees?.includes?.(auth_store?.logged_in_user_details?.user_id);
  }

  function checkResources(filters_array, task) {
    return filters_array.resources?.length && !intersection(filters_array.resources, task.resources).length;
  }

  function checkProgress(filters_array, task) {
    return (
      (filters_array.progress && !isNil(filters_array.progress[0]))
      && (filters_array.progress && !isNil(filters_array.progress[1]))
      && !(
        filters_array.progress[0] / 100 <= task.progress
        && filters_array.progress[1] / 100 >= task.progress
      )
    );
  }

  function checkDateRange(filters_array, task) {
    return (
      filters_array.date_range?.[0]
      && filters_array.date_range?.[1]
      && !(
        dayjs(filters_array.date_range[0]).startOf('day').toDate() <= task.start_date
        && dayjs(filters_array.date_range[1])
          .startOf('day')
          .add(dayjs(task.start_date)
            .isSame(dayjs(task.end_date), 'day')
            ? 0
            : 1, 'day')
          .toDate() >= task.end_date
      )
    );
  }

  function checkActiveDateRange(task) {
    return (
      active_date_range.value?.from
      && active_date_range.value?.to
      && (task.end_date
        <= new Date(active_date_range.value.from)
        || task.start_date
        >= new Date(active_date_range.value.to))
    );
  }

  function checkActivityCodeFilters(filters_array, task, activity_code_filters, activity_code_map) {
    if (task.type !== $g.value.config.types.task && activity_code_filters.length)
      return true;
    const activity_code_values = task.activity_code_values_map || {};
    for (const filter of activity_code_filters) {
      const value = activity_code_values[activity_code_map[filter]];
      if (!filters_array[filter].includes(value))
        return true;
    }
    return false;
  }

  function checkAttributes(filters_array, task, activity_code_filters, activity_code_map) {
    return (
      checkActivityAndMilestone(filters_array, task)
      || checkCriticalAndNotCritical(filters_array, task)
      || checkProgressUpdate(filters_array, task)
      || checkTaskLevel($g.value.calculateTaskLevel(task))
      || checkStatuses(filters_array, task)
      || checkOverdue(filters_array, task)
      || checkAssignedToMe(filters_array, task)
      || checkResources(filters_array, task)
      || checkProgress(filters_array, task)
      || checkDateRange(filters_array, task)
      || checkActiveDateRange(task)
      || checkActivityCodeFilters(filters_array, task, activity_code_filters, activity_code_map)
    );
  }

  function filterTasks(filters_arr) {
    const filters_array = filters_arr || filters.value;
    if (!filters_array)
      return;

    const filtered_task_ids_local = [];

    const { activity_code_filters, activity_code_map } = createFilterMaps(filters_array);

    $g.value.eachTask((task) => {
      if (checkAttributes(filters_array, task, activity_code_filters, activity_code_map))
        return;

      filtered_task_ids_local.push(task.id);
      $g.value.eachParent(
        (parent) => {
          filtered_task_ids_local.push(parent.id);
        },
        task.id,
      );
    });

    filtered_task_ids.value = [...new Set(filtered_task_ids_local)];
  }

  return {
    filterTasks,
  };
}
